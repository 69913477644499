import stickersIconUrl from '@/assets/stickersIcon.svg';
import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Close } from '@radix-ui/react-popover';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import useSendStickerMutation from './api/mutations/sendSticker';
import {
  useAllStickersQuery,
  useRecentStickersQuery,
} from './api/queries/stickers';

function PickerContent({ setOpen }: { setOpen: (open: boolean) => void }) {
  const pathParams = useParams({ strict: false }).dialogId as string;
  const [idUser, idInterlocutor] = pathParams.split('_');

  const all = useAllStickersQuery(idUser);
  const recent = useRecentStickersQuery(idUser);

  const client = useQueryClient();
  const { mutate } = useSendStickerMutation(
    client,
    Number(idUser),
    Number(idInterlocutor)
  );

  return (
    <div className='font-semibold'>
      <div className='flex items-center justify-between bg-muted p-2'>
        <div>STICKERS</div>
        <Close>
          <Cross2Icon className='h-5 w-5' />
        </Close>
      </div>
      <div className='h-64 space-y-2 overflow-y-scroll p-2'>
        {recent.data && (
          <div>
            <div className='text-sm'>Recently used</div>
            <div className='grid grid-cols-5'>
              {recent.data.map(sticker => (
                <img
                  key={sticker.id}
                  src={sticker.url}
                  alt=''
                  className='col-span-1 h-[100px] w-[100px] p-1'
                  onClick={() => {
                    mutate(sticker);
                    setOpen(false);
                  }}
                />
              ))}
            </div>
          </div>
        )}
        {all.data?.categories.map(category => (
          <div key={category.name}>
            <div className='text-sm'>{category.name}</div>
            <div className='grid grid-cols-5'>
              {category.stickers.map(sticker => (
                <img
                  key={sticker.id}
                  src={sticker.url}
                  alt=''
                  className='col-span-1 h-[100px] w-[100px] p-1'
                  onClick={() => {
                    mutate(sticker);
                    setOpen(false);
                  }}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default function StickerPicker({
  portalContainer,
}: {
  portalContainer: HTMLElement | null;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant='outline'>
          <img src={stickersIconUrl} alt='' />
          Stickers
        </Button>
      </PopoverTrigger>
      <PopoverContent className='hidden'>
        {portalContainer &&
          createPortal(<PickerContent setOpen={setOpen} />, portalContainer)}
      </PopoverContent>
    </Popover>
  );
}
