import { useMutation, useQueryClient } from '@tanstack/react-query';
import addNewMessage from '../../../../lib/addNewMessage';
import { MessageData } from '../../../../lib/types/dialog';
import { VideoData } from '../../../../lib/types/media';

export default function useVideoMutation(
  idUser: number,
  idInterlocutor: number
) {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (video: VideoData) => {
      const res = await fetch('/api/v1/chat/send/gallery-video', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          idUser,
          idGalleryVideo: video.idVideo,
          idRegularUser: idInterlocutor,
        }),
      });

      if (!res.ok) throw new Error('Video sending failed');

      return res.json() as Promise<{ idMessage: number }>;
    },
    onSuccess: (res, video) => {
      const newMessage = {
        dateCreated: new Date().toISOString(),
        displayAttributes: [],
        id: res.idMessage,
        idUserFrom: idUser,
        idUserTo: idInterlocutor,
        type: 'video',
        content: {
          id: res.idMessage,
          isPurchased: false,
          url: video.urls.urlThumbnail,
        },
      } satisfies MessageData;

      addNewMessage(client, idUser, idInterlocutor, newMessage);
    },
  });
}
