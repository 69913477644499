import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { PaperPlaneIcon } from '@radix-ui/react-icons';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import useSendAudioMutation from './api/mutations/sendAudio';
import audioQueryOptions from './api/queries/audio';

export default function AudioGrid({
  idUser,
  idInterlocutor,
  setOpen,
}: {
  idUser: number;
  idInterlocutor: number;
  setOpen: (open: boolean) => void;
}) {
  const { data } = useQuery(audioQueryOptions(idUser));
  const { mutate, isPending } = useSendAudioMutation();

  const [attached, setAttached] = useState(() => new Set<number>());

  return (
    <div className='space-y-2'>
      <div className='grid h-[60vh] grid-cols-3 gap-4 overflow-y-auto'>
        {data?.data.audio.map(audio => (
          <div
            className={cn(
              'flex h-60 flex-col justify-between rounded-md border-4 p-2',
              attached.has(audio.id) && 'border-green-500'
            )}
            onClick={() => {
              if (!attached.has(audio.id)) {
                attached.add(audio.id);
                setAttached(new Set(attached));
              } else {
                attached.delete(audio.id);
                setAttached(new Set(attached));
              }
            }}
          >
            <div className='overflow-hidden'>{audio.title}</div>
            <div className='text-right font-bold'>
              Duration: {audio.duration} s
            </div>
          </div>
        ))}
      </div>
      <div className='flex justify-end'>
        <Button
          className='gap-1 font-bold'
          disabled={attached.size === 0 || isPending}
          onClick={() => {
            data?.data.audio
              ?.filter(audio => attached.has(audio.id))
              .map(audio =>
                mutate(
                  { idUser, idRegularUser: idInterlocutor, audio },
                  { onSuccess: () => setOpen(false) }
                )
              );
          }}
        >
          Send
          <PaperPlaneIcon />
        </Button>
      </div>
    </div>
  );
}
