import CheckIcon from '@/components/svg/checkIcon';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import DataTable from '@/components/ui/data-table';
import { User } from '@/lib/types/user';
import { usersQueryOptions } from '@/modules/employeesSettings/api/users';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import AddEmployeeDialog from './addEmployeeDialog';
import useDeleteEmployeeMutation from './api/deleteEmployee';
import EditEmployeeDialog from './editEmployeeDialog';

const employeesColumn: ColumnDef<User>[] = [
  {
    accessorKey: 'username',
    header: 'Username',
  },
  {
    accessorKey: 'is_admin',
    header: 'Admin',
    cell: ({ row }) => {
      const user = row.original;

      return user.is_admin ? <CheckIcon className='fill-green-600' /> : null;
    },
    enableHiding: false,
  },
  {
    id: 'update',
    cell: ({ row }) => {
      const user = row.original;

      return <EditEmployeeDialog user={user} />;
    },
  },
  {
    id: 'delete',
    cell: ({ row }) => {
      const user = row.original;

      const { mutate, isPending } = useDeleteEmployeeMutation();

      return (
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button variant='destructive' disabled={user.is_admin || isPending}>
              Delete
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
              <AlertDialogDescription>
                This action cannot be undone. This will permanently delete
                employee's account and remove data from our servers.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter className='sm:space-x-4'>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction
                className='bg-destructive hover:bg-destructive/90'
                onClick={() => mutate(user.id)}
              >
                Delete
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      );
    },
  },
];

export default function EmployeesSettings() {
  const { data } = useQuery(usersQueryOptions);

  if (data)
    return (
      <>
        <DataTable columns={employeesColumn} data={data} />
        <div className='flex justify-end'>
          <AddEmployeeDialog />
        </div>
      </>
    );
}
