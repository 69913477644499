export default function NavMessagesIcon({ className }: { className?: string }) {
  return (
    <svg
      viewBox='0 0 24 24'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.944 3.529A1.952 1.952 0 0 0 19.6 3H4.4c-1.045 0-1.9.803-1.9 1.805V21.05l3.8-3.61h13.3c.504 0 .987-.19 1.343-.529a1.76 1.76 0 0 0 .557-1.276V4.805c0-.479-.2-.938-.556-1.276zM6.3 7.45h11.4v1.9H6.3v-1.9zm0 3.8h8.55v1.9H6.3v-1.9z'
      ></path>
    </svg>
  );
}
