import { QueryClient, UseQueryOptions } from '@tanstack/react-query';
import { DialogData } from '../types/dialog';
import { ProfileData } from '../types/profiles';

export default function byPairsQueryOptions(
  client: QueryClient,
  idInterlocutor: number,
  idUser?: number
) {
  return {
    queryKey: ['byPairs', { idInterlocutor, idUser }],
    queryFn: async () => {
      const params = new URLSearchParams({
        idsRegularUser: idInterlocutor.toString(),
      });

      if (idUser) {
        params.set('idUser', idUser.toString());
      }

      const res = await fetch(
        '/api/v1/chat/dialogs/by-pairs?' + params.toString()
      );
      const data = (await res.json()) as {
        dialogs: DialogData[];
        profiles: ProfileData[];
      };

      for (const dialog of data.dialogs)
        client.setQueryData(
          [
            'dialog',
            { idUser: dialog.idUser, idInterlocutor: dialog.idInterlocutor },
          ],
          dialog
        );

      for (const profile of data.profiles)
        client.setQueryData(['profile', profile.id], profile);

      return data;
    },
  } satisfies UseQueryOptions;
}
