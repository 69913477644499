import { cn } from '@/lib/utils';

export default function CheckIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={cn('h-7 w-7', className)}
      viewBox='0 0 20 20'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.5 13.5 4 10l-1.167 1.167L7.5 15.834l10-10-1.167-1.167L7.5 13.5z'
      ></path>
    </svg>
  );
}
