import { Button } from '@/components/ui/button';
import useOpenInAppMutation from '@/lib/mutations/openInApp';
import { useQuery } from '@tanstack/react-query';
import restrictionsQueryOptions from './api/queries/restrictions';
import { cn } from '@/lib/utils';

function PostsIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      viewBox='0 0 12 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M3.975 18 5 10.95H1.35a.418.418 0 0 1-.4-.25.482.482 0 0 1 0-.475L7.075 0H8.1L7.075 7.025h3.6c.183 0 .32.083.412.25.092.167.096.325.013.475L5 18H3.975z'></path>
    </svg>
  );
}

export default function PostsButton({
  className,
  idUser,
  idInterlocutor,
}: {
  className?: string;
  idUser: number;
  idInterlocutor: number;
}) {
  const { data } = useQuery(restrictionsQueryOptions(idUser, idInterlocutor));

  const sortedIds = [idUser, idInterlocutor].sort((a, b) => a - b);
  const { mutate, isPending } = useOpenInAppMutation(
    `https://talkytimes.com/chat/${sortedIds[0]}_${sortedIds[1]}/posts`
  );

  return (
    <Button
      variant='outline'
      className={cn('gap-1', className)}
      disabled={!data?.allowSendPost || isPending}
      onClick={() => mutate(idUser)}
    >
      <PostsIcon className='h-6 w-5 fill-primary' />
      Posts
    </Button>
  );
}
