import { UseQueryOptions } from '@tanstack/react-query';

export const girlsQueryOptions = {
  queryKey: ['girls'],
  queryFn: async () => {
    const res = await fetch('/api/v1/girls');

    return res.json() as Promise<
      {
        id: number;
        talkytimes_id: number;
        email: string;
      }[]
    >;
  },
} satisfies UseQueryOptions;
