import typingQueryOptions from '@/lib/queries/typing';
import { ProfileData } from '@/lib/types/profiles';
import { useQuery, useQueryClient } from '@tanstack/react-query';

function lastTimeOnlineText(lastVisit?: string) {
  if (!lastVisit) return;

  const visitDate = new Date(lastVisit);
  visitDate.setMinutes(visitDate.getMinutes() - visitDate.getTimezoneOffset());

  const diff = Date.now() - visitDate.getTime();

  const diffInSeconds = diff / 1000;

  const days = Math.floor(diffInSeconds / 86400);
  if (days > 1) return `${days} days ago`;
  else if (days === 1) return 'a day ago';

  const hours = Math.floor(diffInSeconds / 3600);
  if (hours > 1) return `${hours} hours ago`;
  else if (hours === 1) return 'an hour ago';

  const minutes = Math.floor(diffInSeconds / 60);
  if (minutes > 1) return `${minutes} minutes ago`;
  else if (minutes === 1) return 'a minute ago';
}

export default function InterlocutorStatus({
  idUser,
  interlocutorProfileData,
  isOnline,
}: {
  idUser: number;
  interlocutorProfileData: ProfileData;
  isOnline: boolean;
}) {
  const client = useQueryClient();

  const { data } = useQuery(
    typingQueryOptions(client, idUser, interlocutorProfileData.id)
  );

  if (data) return <div className='text-primary'>typing...</div>;

  if (isOnline) return <div className='text-green-500'>online</div>;

  return (
    <div className='text-muted-foreground'>
      {lastTimeOnlineText(interlocutorProfileData.last_visit)}
    </div>
  );
}
