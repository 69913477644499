import { cn } from '@/lib/utils';

export default function OnlineIndicator({
  className,
  isOnline,
}: {
  className?: string;
  isOnline?: boolean;
}) {
  return (
    <div
      className={cn(
        'h-4 w-4 rounded-full border-2 border-white',
        className,
        isOnline ? 'bg-green-500' : 'bg-slate-400'
      )}
    ></div>
  );
}
