import { QueryClient, UseMutationOptions } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

export default function logoutMutationOptions(client: QueryClient) {
  const navigate = useNavigate();

  return {
    mutationFn: async () =>
      fetch('/api/v1/auth/logout', {
        method: 'POST',
      }),
    onSuccess: () => {
      client.clear();
      navigate({ to: '/login' });
    },
  } satisfies UseMutationOptions;
}
