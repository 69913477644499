import { useMutation, useQueryClient } from '@tanstack/react-query';
import addNewMessage from '../../../../lib/addNewMessage';
import { MessageData } from '../../../../lib/types/dialog';
import { PhotoData } from '../../../../lib/types/media';

export default function useSendPhotoMutation(
  idUser: number,
  idInterlocutor: number
) {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (photos: PhotoData[]) => {
      const url =
        photos.length === 1
          ? '/api/v1/chat/send/gallery-photo'
          : '/api/v1/chat/send/gallery-photos';

      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          idUser,
          idRegularUser: idInterlocutor,
          ...(photos.length === 1
            ? { idGalleryPhoto: photos[0].idPhoto }
            : { idsGalleryPhoto: photos.map(photo => photo.idPhoto) }),
        }),
      });

      if (!res.ok) throw new Error('Photo sending failed');

      return res.json() as Promise<{ idMessage: number }>;
    },
    onSuccess: (res, photos) => {
      const baseNewMessage = {
        dateCreated: new Date().toISOString(),
        displayAttributes: [] as [],
        id: res.idMessage,
        idUserFrom: idUser,
        idUserTo: idInterlocutor,
      };

      const newMessage: MessageData =
        photos.length === 1
          ? {
              ...baseNewMessage,
              type: 'photo',
              content: {
                id: photos[0].idPhoto,
                url: photos[0].urls.urlPreview,
                text: '',
              },
            }
          : {
              ...baseNewMessage,
              type: 'photo_batch',
              content: {
                photos: photos.map(photo => ({
                  id: photo.idPhoto,
                  url: photo.urls.urlPreview,
                })),
              },
            };

      addNewMessage(client, idUser, idInterlocutor, newMessage);
    },
  });
}
