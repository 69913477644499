import { getFeedback } from '@sentry/react';
import { useEffect } from 'react';

function createWidget() {
  return getFeedback()?.createWidget();
}
export default function SentryFeedbackWidget() {
  useEffect(() => {
    const widget = createWidget();

    return () => widget?.removeFromDom();
  }, []);

  return null;
}
