import { useMutation, useQueryClient } from '@tanstack/react-query';
import searchSpammerQueryOptions from './searchSpammer';

export default function useSearchSpammerMutation(girl_id: number) {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (data: { timeout?: number; started?: boolean }) => {
      const res = await fetch(`/api/v1/search-spammer/?girl_id=${girl_id}`, {
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) throw new Error('Spammer patching failed');

      return res;
    },
    onSettled: async () => {
      return await qc.invalidateQueries({
        queryKey: searchSpammerQueryOptions.queryKey,
      });
    },
  });
}
