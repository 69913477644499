import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import MailSpammer from '@/modules/mailSpammer';
import PrimeSpammer from '@/modules/primeSpammer';
import SearchSpammer from '@/modules/searchSpammer';
import { createFileRoute } from '@tanstack/react-router';

const tags = ['name', 'country', 'age'];

export const Route = createFileRoute('/_authenticated/_layout/spammer')({
  component: Spammer,
});

function Spammer() {
  return (
    <main className='container flex justify-center text-center'>
      <Tabs defaultValue='prime' className='w-[56rem]'>
        <TabsList className='w-full'>
          <TabsTrigger value='prime' className='grow font-semibold'>
            Prime Spammer
          </TabsTrigger>
          <TabsTrigger value='mail' className='grow font-semibold'>
            Mail Spammer
          </TabsTrigger>
          <TabsTrigger value='search' className='grow font-semibold'>
            Search Spammer
          </TabsTrigger>
        </TabsList>
        <TabsContent value='prime' className='space-y-2 pb-2'>
          <div>
            <h1 className='text-lg font-bold'>Prime Spammer</h1>
            <h2>
              Доступны теги для использования в инвайтах:{' '}
              {tags
                .map(tag => (
                  <span
                    key={tag}
                    className='cursor-pointer select-none font-mono text-primary'
                    onClick={() => navigator.clipboard.writeText(`{${tag}}`)}
                  >{`{${tag}}`}</span>
                ))
                .reduce((prev, curr) => (
                  <>
                    {prev}/{curr}
                  </>
                ))}
              .
            </h2>
          </div>
          <PrimeSpammer />
        </TabsContent>
        <TabsContent value='mail' className='space-y-2 pb-2'>
          <div>
            <h1 className='text-lg font-bold'>Mail Spammer</h1>
            <h2>
              Доступны теги для использования в инвайтах:{' '}
              {tags
                .map(tag => (
                  <span
                    key={tag}
                    className='cursor-pointer select-none font-mono text-primary'
                    onClick={() => navigator.clipboard.writeText(`{${tag}}`)}
                  >{`{${tag}}`}</span>
                ))
                .reduce((prev, curr) => (
                  <>
                    {prev}/{curr}
                  </>
                ))}
              .
            </h2>
          </div>
          <MailSpammer />
        </TabsContent>
        <TabsContent value='search' className='space-y-2 pb-2'>
          <h1 className='text-lg font-bold'>Search Spammer</h1>
          <SearchSpammer />
        </TabsContent>
      </Tabs>
    </main>
  );
}
