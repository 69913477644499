import { UseQueryOptions } from '@tanstack/react-query';

export const isAdminQueryOptions = {
  queryKey: ['isAdmin'],
  queryFn: async () => {
    const res = await fetch('/api/v1/users/is-admin');

    if (!res.ok) throw new Error('Admin check failed');

    return res.json() as Promise<{ admin: boolean }>;
  },
} satisfies UseQueryOptions;
