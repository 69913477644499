import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import DataTable from '@/components/ui/data-table';
import { Switch } from '@/components/ui/switch';
import { girlsProfilesQueryOptions } from '@/lib/queries/girlsProfiles';
import { GirlProfile } from '@/lib/types/profiles';
import { DownloadIcon, PlayIcon, StopIcon } from '@radix-ui/react-icons';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { girlsQueryOptions } from '@/lib/queries/girls';
import useSpammerMutation from './api/patchSpammer';
import spammerQueryOptions, { SpammerResponse } from './api/spammer';
import TextInput from './textInput';
import TimeoutInput from './timeoutInput';

const columns: ColumnDef<GirlProfile & { spammer?: SpammerResponse }>[] = [
  {
    accessorKey: 'personal.avatar_large',
    header: () => <div className='text-center'>Avatar</div>,
    cell: ({ row }) => {
      const profile = row.original;

      return (
        <Avatar>
          <AvatarImage src={profile.personal.avatar_large} />
          <AvatarFallback>{profile.name}</AvatarFallback>
        </Avatar>
      );
    },
  },
  {
    accessorKey: 'name',
    header: () => <div className='text-center'>Name</div>,
  },
  {
    id: 'text',
    header: () => <div className='text-center'>Text</div>,
    cell: ({ row }) => {
      if (!row.original.spammer) return;

      return <TextInput spammer={row.original.spammer} />;
    },
  },
  {
    id: 'timeout',
    header: () => <div className='text-center'>Timeout, s</div>,
    cell: ({ row }) => {
      if (!row.original.spammer) return;

      return <TimeoutInput spammer={row.original.spammer} />;
    },
  },
  {
    id: 'online',
    header: () => <div className='text-center'>Online only</div>,
    cell: ({ row }) => {
      const { mutate, isPending, variables } = useSpammerMutation(
        row.original.spammer?.girl_id ?? -1
      );

      if (!row.original.spammer) return;

      return (
        <div className='flex justify-center'>
          <Switch
            checked={
              isPending
                ? variables.online_only
                : row.original.spammer.online_only
            }
            onCheckedChange={value => mutate({ online_only: value })}
            disabled={isPending}
          />
        </div>
      );
    },
  },
  {
    id: 'start',
    header: () => <div className='text-center'>Start</div>,
    cell: ({ row }) => {
      const { mutate, isPending, variables } = useSpammerMutation(
        row.original.spammer?.girl_id ?? -1
      );

      if (!row.original.spammer) return;

      const isStarted = isPending
        ? !!variables.started
        : row.original.spammer.started;

      return (
        <Button
          variant='outline'
          disabled={isPending || !row.original.spammer.text}
          onClick={() => mutate({ started: !isStarted })}
        >
          {isStarted ? <StopIcon /> : <PlayIcon />}
        </Button>
      );
    },
  },
  {
    accessorKey: 'spammer.count',
    header: () => <div className='text-center'>Sent</div>,
  },
  {
    id: 'download',
    header: () => <div className='text-center'>History</div>,
    cell: ({ row }) => (
      <Button variant='outline' disabled={!row.original.spammer}>
        <a
          href={`/api/v1/spammer/invitations.txt?girl_id=${row.original.spammer?.girl_id}`}
          download
        >
          <DownloadIcon />
        </a>
      </Button>
    ),
  },
];

export default function PrimeSpammer() {
  const { data: girlsData } = useQuery(girlsQueryOptions);
  const { data: girlsProfilesData } = useQuery(girlsProfilesQueryOptions);
  const { data: spammerData } = useQuery(spammerQueryOptions);

  if (!girlsData || !girlsProfilesData || !spammerData) return;

  const data = girlsProfilesData.map(girlProfile => {
    const girl = girlsData.find(girl => girlProfile.id === girl.talkytimes_id);

    return {
      ...girlProfile,
      spammer: spammerData.find(spammer => spammer.girl_id === girl?.id),
    };
  });

  if (data) return <DataTable columns={columns} data={data} />;
}
