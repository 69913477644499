import { Textarea } from '@/components/ui/textarea';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';
import { SpammerResponse } from './api/mailSpammer';
import useMailSpammerMutation from './api/patchMailSpammer';

export default function TextInput({ spammer }: { spammer: SpammerResponse }) {
  const { mutate, isPending, isSuccess, isError, variables } =
    useMailSpammerMutation(spammer.girl_id);

  const [text, setText] = useState(spammer.text ?? '');

  useEffect(() => {
    if (spammer.text !== text) setText(spammer.text ?? '');
  }, [spammer.text]);

  return (
    <Textarea
      className={cn(
        'min-h-9 bg-background',
        text ? 'h-20' : 'h-9',
        isError && variables.text === text && 'ring-2 ring-red-500',
        isSuccess && variables.text === text && 'ring-2 ring-green-500',
        'resize-none'
      )}
      value={text}
      onChange={e => setText(e.target.value)}
      disabled={isPending}
      placeholder='Write a mail...'
      onBlur={() => {
        const trimmedText = text.trim();

        if (trimmedText === spammer.text) return;

        if (!trimmedText) {
          setText(spammer.text ?? '');
          return;
        }

        mutate({ text: trimmedText });
      }}
      onKeyDown={e => {
        if (e.key !== 'Enter') return;

        const trimmedText = text.trim();

        if (trimmedText === spammer.text) return;

        if (!trimmedText) return;

        mutate({ text: trimmedText });
      }}
    />
  );
}
