import { useMutation, useQueryClient } from '@tanstack/react-query';
import mailSpammerQueryOptions from './mailSpammer';

export default function useMailSpammerMutation(girl_id: number) {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (data: {
      text?: string;
      timeout?: number;
      online_only?: boolean;
      started?: boolean;
      photos_ids?: number[];
      videos_ids?: number[];
    }) => {
      const res = await fetch(`/api/v1/mail-spammer/?girl_id=${girl_id}`, {
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!res.ok) throw new Error('Spammer patching failed');

      return res;
    },
    onSettled: async () => {
      return await qc.invalidateQueries({
        queryKey: mailSpammerQueryOptions.queryKey,
      });
    },
  });
}
