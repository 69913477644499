import { UseQueryOptions } from '@tanstack/react-query';
import { User } from '../../../lib/types/user';

export const usersQueryOptions = {
  queryKey: ['users'],
  queryFn: async () => {
    const res = await fetch('/api/v1/users/');

    return res.json() as Promise<User[]>;
  },
} satisfies UseQueryOptions;
