import DialogView from '@/components/dialogView';
import ErrorPage from '@/components/errorPage';
import byPairsQueryOptions from '@/lib/queries/byPairs';
import profileQueryOptions from '@/lib/queries/profile';
import DialogHeader from '@/modules/dialogHeader';
import DialogInput from '@/modules/dialogInput';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createFileRoute } from '@tanstack/react-router';
import { useRef } from 'react';

export const Route = createFileRoute(
  '/_authenticated/_layout/_socket/chat/$dialogId'
)({
  component: $DialogId,
  errorComponent: ErrorPage,
});

function $DialogId() {
  const [idUser, idInterlocutor] = Route.useParams()
    .dialogId.split('_')
    .map(Number);

  const client = useQueryClient();
  const portalContainerRef = useRef<HTMLDivElement>(null);

  const interlocutorProfileData = useQuery(
    profileQueryOptions(client, idInterlocutor)
  ).data;

  useQuery({
    ...byPairsQueryOptions(client, idInterlocutor, idUser),
    enabled: !interlocutorProfileData,
  });

  return (
    <div className='relative flex w-full flex-col border shadow-xl'>
      {interlocutorProfileData && (
        <DialogHeader
          idUser={idUser}
          interlocutorProfileData={interlocutorProfileData}
        />
      )}
      <DialogView idUser={idUser} idInterlocutor={idInterlocutor} />
      <div ref={portalContainerRef} className='shrink-0' />
      <DialogInput portalContainer={portalContainerRef.current} />
    </div>
  );
}
