import { QueryClient, useMutation } from '@tanstack/react-query';
import addNewMessage from '../../../../lib/addNewMessage';
import { MessageData } from '../../../../lib/types/dialog';

export default function useSendMessageMutation(
  client: QueryClient,
  idUser: number,
  idInterlocutor: number
) {
  return useMutation({
    mutationFn: async (text: string) => {
      const res = await fetch('/api/v1/chat/send/text', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          idUser,
          message: text,
          idRegularUser: idInterlocutor,
        }),
      });

      if (!res.ok) throw new Error('Message sending failed');

      return res.json() as Promise<{ idMessage: number }>;
    },
    onSuccess: (res, text) => {
      const newMessage = {
        dateCreated: new Date().toISOString(),
        displayAttributes: [],
        id: res.idMessage,
        idUserFrom: idUser,
        idUserTo: idInterlocutor,
        type: 'message',
        content: {
          message: text,
        },
      } satisfies MessageData;

      addNewMessage(client, idUser, idInterlocutor, newMessage);
    },
  });
}
