import logoutMutationOptions from '@/lib/mutations/logout';
import usernameQueryOptions from '@/lib/queries/username';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';

export default function UserActions({ className }: { className?: string }) {
  const client = useQueryClient();

  const { data } = useQuery(usernameQueryOptions());
  const { mutate } = useMutation(logoutMutationOptions(client));

  return (
    <div className={className}>
      <DropdownMenu>
        <DropdownMenuTrigger className='text-lg font-semibold'>
          {data}
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <Link to='/settings'>
            <DropdownMenuItem className='text-lg'>Settings</DropdownMenuItem>
          </Link>
          <DropdownMenuItem className='text-lg' onClick={() => mutate()}>
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
