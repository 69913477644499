import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { zodResolver } from '@hookform/resolvers/zod';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import useAddEmployeeMutation from './api/addEmployee';

const addEmployeeFormSchema = z.object({
  username: z
    .string()
    .min(1, { message: 'Username must be at least 1 characters.' })
    .trim(),
  password: z
    .string()
    .min(8, { message: 'Password length must be at least 8 characters' })
    .refine(value => /[a-zA-Z]/.test(value) && /\d/.test(value), {
      message: 'Password must contain at least one letter and one number',
    }),
});

export default function AddEmployeeDialog() {
  const { mutate, isPending, error } = useAddEmployeeMutation();

  const [open, setOpen] = useState(false);

  const form = useForm<z.infer<typeof addEmployeeFormSchema>>({
    resolver: zodResolver(addEmployeeFormSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Add</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add new employee</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(values =>
              mutate(values, {
                onSuccess: () => {
                  setOpen(false);
                  form.reset();
                },
              })
            )}
          >
            <fieldset disabled={isPending} className='space-y-3'>
              <FormField
                control={form.control}
                name='username'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Username</FormLabel>
                    <FormControl>
                      <Input placeholder='girl@example.com' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='password'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <Input
                        type='password'
                        placeholder='qwerty11'
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {error && (
                <Alert variant='destructive'>
                  <ExclamationTriangleIcon className='h-4 w-4' />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{error.message}</AlertDescription>
                </Alert>
              )}
              <DialogFooter>
                <Button type='submit'>Add</Button>
              </DialogFooter>
            </fieldset>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
