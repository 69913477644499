import { DialogData } from './types/dialog';

export default function messageContentString(dialogData: DialogData) {
  switch (dialogData.lastMessage.type) {
    case 'message':
      return dialogData.lastMessage.content.message;
    case 'sticker':
      if (dialogData.idUser === dialogData.lastMessage.idUserFrom)
        return 'You: Sent a sticker';
      else return 'Sent a sticker';
    case 'like_newsfeed_post':
      return '👍 Liked a post';
    case 'likephoto':
      return '👍 liked the photo';
    case 'wink':
      return 'Winked';
    case 'system':
      return dialogData.lastMessage.content.message;
    case 'virtual_gift':
      if (dialogData.idUser === dialogData.lastMessage.idUserFrom)
        return 'You: Sent a gift';
      else return 'Sent a gift';
    case 'audio':
      return 'Sent an audio';
    case 'photo':
      return 'Sent a photo';
    case 'photo_batch':
      return 'Sent photos';
    case 'video':
      return 'Sent a video';
    default:
      return 'Unknown message type';
  }
}
