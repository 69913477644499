import { usersQueryOptions } from '@/modules/employeesSettings/api/users';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function useDeleteEmployeeMutation() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => {
      const res = await fetch(`/api/v1/users/${id}`, {
        method: 'DELETE',
      });

      if (!res.ok) throw new Error('Deleting user failed');

      return res;
    },
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: usersQueryOptions.queryKey });
    },
  });
}
