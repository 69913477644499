export default function ApprovedMediaIcon({
  className,
}: {
  className?: string;
}) {
  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path d='M10 1.667c-4.583 0-8.333 3.75-8.333 8.333s3.75 8.333 8.333 8.333 8.333-3.75 8.333-8.333S14.583 1.667 10 1.667zm0 15A6.675 6.675 0 0 1 3.333 10 6.676 6.676 0 0 1 10 3.333 6.675 6.675 0 0 1 16.667 10 6.675 6.675 0 0 1 10 16.667zm3.825-10.35-5.492 5.491-2.158-2.15L5 10.833l3.333 3.334L15 7.5l-1.175-1.183z'></path>
    </svg>
  );
}
