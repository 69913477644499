export default function ErrorPage() {
  return (
    <div className='flex h-full w-full flex-col items-center justify-center'>
      <div className='text-2xl font-bold'>Error! 😰</div>
      <div className='text-lg text-muted-foreground'>
        We're already investigating the issue
      </div>
    </div>
  );
}
