import { usersQueryOptions } from '@/modules/employeesSettings/api/users';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export default function useEditEmployeeMutation() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async ({
      id,
      username,
      password,
    }: {
      id: number;
      username?: string;
      password?: string;
    }) => {
      const res = await fetch(`/api/v1/users/${id}`, {
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      if (!res.ok) throw new Error('Creating new user failed');

      return res;
    },
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: usersQueryOptions.queryKey });
    },
  });
}
