export default function NavMailsIcon({ className }: { className?: string }) {
  return (
    <svg
      viewBox='0 0 24 24'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M19.2 5H4.8c-.99 0-1.791.787-1.791 1.75L3 17.25c0 .962.81 1.75 1.8 1.75h14.4c.99 0 1.8-.788 1.8-1.75V6.75C21 5.787 20.19 5 19.2 5zm0 3.5L12 12.875 4.8 8.5V6.75l7.2 4.375 7.2-4.375V8.5z'></path>
    </svg>
  );
}
