import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import { useEffect, useState } from 'react';
import useSpammerMutation from './api/patchSpammer';
import { SpammerResponse } from './api/spammer';

export default function TextInput({ spammer }: { spammer: SpammerResponse }) {
  const { mutate, isPending, isSuccess, isError, variables } =
    useSpammerMutation(spammer.girl_id);

  const [text, setText] = useState(spammer.text ?? '');

  useEffect(() => {
    if (spammer.text !== text) setText(spammer.text ?? '');
  }, [spammer.text]);

  return (
    <Input
      className={cn(
        'w-96',
        isError && variables.text === text && 'ring-2 ring-red-500',
        isSuccess && variables.text === text && 'ring-2 ring-green-500'
      )}
      value={text}
      onChange={e => setText(e.target.value)}
      disabled={isPending}
      placeholder='Write an invite...'
      onBlur={() => {
        const trimmedText = text.trim();

        if (trimmedText === spammer.text) return;

        if (!trimmedText) {
          setText(spammer.text ?? '');
          return;
        }

        mutate({ text: trimmedText });
      }}
      onKeyDown={e => {
        if (e.key !== 'Enter') return;

        const trimmedText = text.trim();

        if (trimmedText === spammer.text) return;

        if (!trimmedText) return;

        mutate({ text: trimmedText });
      }}
    />
  );
}
