import { PhotoData, VideoData } from '@/lib/types/media';
import { useInfiniteQuery } from '@tanstack/react-query';

type EntityType = 'photo' | 'video';

type GalleryData<T extends EntityType> = { cursor: string } & (T extends 'photo'
  ? { photos: PhotoData[] }
  : { videos: VideoData[] });

export default function useGalleryInfiniteQuery<T extends EntityType>(
  entity: T,
  idUser: number
) {
  return useInfiniteQuery({
    queryKey: ['gallery', entity, idUser],
    queryFn: async ({ pageParam }) => {
      const body = {
        cursor: pageParam,
        limit: 40,
        statuses: [],
        tags: [],
      };

      const res = await fetch(
        `/api/v1/gallery/${entity}/list?idUser=${idUser}`,
        {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            ...body,
            ...(entity === 'photo' && { idAlbum: null, idAlbumExcluded: null }),
            ...(entity === 'video' && { excludeTags: [] }),
          }),
        }
      );

      return res.json() as Promise<GalleryData<T>>;
    },
    initialPageParam: '',
    getNextPageParam: lastPage => (lastPage.cursor ? lastPage.cursor : null),
    gcTime: 0,
  });
}
