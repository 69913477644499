import ErrorPage from '@/components/errorPage';
import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { lazy } from 'react';

type RouterContext = {
  queryClient: QueryClient;
};

const TanStackRouterDevtools = import.meta.env.PROD
  ? () => null // Render nothing in production
  : lazy(() =>
      // Lazy load in development
      import('@tanstack/router-devtools').then(res => ({
        default: res.TanStackRouterDevtools,
        // For Embedded Mode
        // default: res.TanStackRouterDevtoolsPanel
      }))
    );

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => (
    <>
      <TanStackRouterDevtools />
      <Outlet />
    </>
  ),
  errorComponent: ErrorPage,
  notFoundComponent: NotFound,
});

function NotFound() {
  return (
    <main className='flex h-full items-center justify-center'>
      <div className='text-2xl'>Page not found 😢</div>
    </main>
  );
}
