import { Input } from '@/components/ui/input';
import useSearchSpammerMutation from './api/patchSearchSpammer';
import { SpammerResponse } from './api/searchSpammer';
import { useEffect, useState } from 'react';
import { cn } from '@/lib/utils';

const minTimeout = 10;

export default function TimeoutInput({
  spammer,
}: {
  spammer: SpammerResponse;
}) {
  const { mutate, isPending, isSuccess, isError, variables } =
    useSearchSpammerMutation(spammer.girl_id);

  const [timeoutValue, setTimeoutValue] = useState<number | null>(
    spammer.timeout
  );

  useEffect(() => {
    if (spammer.timeout !== timeoutValue) setTimeoutValue(spammer.timeout);
  }, [spammer.timeout]);

  return (
    <Input
      className={cn(
        'max-w-24',
        isError && variables.timeout === timeoutValue && 'ring-2 ring-red-500',
        isSuccess &&
          variables.timeout === timeoutValue &&
          'ring-2 ring-green-500'
      )}
      value={timeoutValue ?? ''}
      pattern='[0-9]*'
      disabled={isPending}
      placeholder='e.g. 30'
      onChange={e => {
        if (!e.target.validity.valid) return;

        if (e.target.value === '') setTimeoutValue(null);
        else setTimeoutValue(Number(e.target.value));
      }}
      onBlur={() => {
        if (timeoutValue === spammer.timeout) return;

        if (timeoutValue === null || timeoutValue < minTimeout) {
          setTimeoutValue(spammer.timeout);
          return;
        }

        mutate({ timeout: timeoutValue });
      }}
      onKeyDown={e => {
        if (e.key !== 'Enter') return;

        if (timeoutValue === spammer.timeout) return;

        if (timeoutValue === null || timeoutValue < minTimeout) {
          setTimeoutValue(spammer.timeout);
          return;
        }

        mutate({ timeout: timeoutValue });
      }}
    />
  );
}
