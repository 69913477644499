export default function NavStreamsIcon({ className }: { className?: string }) {
  return (
    <svg
      viewBox='0 0 24 24'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='m19.625 18.16 1.41 1.41c3.92-3.91 3.92-10.24 0-14.14l-1.41 1.41c3.13 3.13 3.13 8.2 0 11.32z'></path>
      <path d='m16.795 15.33 1.41 1.41a6 6 0 0 0 0-8.48l-1.41 1.41a4.008 4.008 0 0 1 0 5.66zM4.375 18.16l-1.41 1.41c-3.92-3.91-3.92-10.24 0-14.14l1.41 1.41c-3.13 3.13-3.13 8.2 0 11.32z'></path>
      <path d='m7.205 15.33-1.41 1.41a6 6 0 0 1 0-8.48l1.41 1.41a4.008 4.008 0 0 0 0 5.66z'></path>
      <circle
        cx='12.035'
        cy='12.57'
        r='4'
        transform='rotate(-90 12.035 12.57)'
      ></circle>
    </svg>
  );
}
