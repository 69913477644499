import { useMutation } from '@tanstack/react-query';

export default function useChatReadMutation(
  idUser: number,
  idInterlocutor: number
) {
  return useMutation({
    mutationFn: async () => {
      const res = await fetch('/api/v1/chat/read', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          idUser,
          idRegularUser: idInterlocutor,
        }),
      });

      return res;
    },
  });
}
