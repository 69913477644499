import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { zodResolver } from '@hookform/resolvers/zod';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import useEditGirlMutation from './api/editGirl';
import { EntityProfile } from './types/entityProfile';

const editGirlFormSchema = z.object({
  email: z.string().trim(),
  password: z.string(),
});

export default function EditGirlDialogContent({
  entity,
  setOpen,
}: {
  entity: EntityProfile;
  setOpen: (value: boolean) => void;
}) {
  const { mutate, isPending, error } = useEditGirlMutation();

  const form = useForm<z.infer<typeof editGirlFormSchema>>({
    resolver: zodResolver(editGirlFormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Edit girl</DialogTitle>
      </DialogHeader>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(values => {
            const params: Parameters<typeof mutate>[0] = {
              id: entity.id,
            };

            if (values.email) params.email = values.email;
            if (values.password) params.password = values.password;

            mutate(params, {
              onSuccess: () => {
                setOpen(false);
                form.reset();
              },
            });
          })}
        >
          <fieldset disabled={isPending} className='space-y-3'>
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>New email</FormLabel>
                  <FormControl>
                    <Input placeholder={entity.email} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>New password</FormLabel>
                  <FormControl>
                    <Input
                      type='password'
                      placeholder='(No changes)'
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {error && (
              <Alert variant='destructive'>
                <ExclamationTriangleIcon className='h-4 w-4' />
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error.message}</AlertDescription>
              </Alert>
            )}
            <DialogFooter>
              <Button type='submit' disabled={!form.formState.isDirty}>
                Edit
              </Button>
            </DialogFooter>
          </fieldset>
        </form>
      </Form>
    </DialogContent>
  );
}
