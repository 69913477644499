import useRestrictionQuery from '@/lib/queries/restriction';

export default function RestrictionInfo({
  idUser,
  idInterlocutor,
  isBlocked,
}: {
  idUser: number;
  idInterlocutor: number;
  isBlocked?: boolean;
}) {
  const { data } = useRestrictionQuery(idUser, idInterlocutor);

  if (isBlocked)
    return (
      <div className='rounded-lg bg-blue-100 px-3 py-2 text-sm font-semibold text-red-600'>
        User blocked
      </div>
    );

  if (data)
    return (
      <div className='rounded-lg bg-blue-100 px-3 py-2 text-sm'>
        {data.data.messagesLeft === 0 ? 'No' : data.data.messagesLeft} messages
        and {data.data.lettersLeft === 0 ? 'No' : data.data.lettersLeft} mails
        available
      </div>
    );
  else
    return <div className='rounded-lg bg-blue-100 px-3 py-2 text-sm'>...</div>;
}
