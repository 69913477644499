import { useMutation, useQueryClient } from '@tanstack/react-query';
import dialogQueryOptions from '../queries/dialog';
import { DialogData } from '../types/dialog';

export default function useBookmarkMutation(
  idUser: number,
  idInterlocutor: number
) {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (bookmark: boolean) => {
      const res = await fetch('/api/v1/chat/bookmark', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          idUser,
          bookmark,
          idRegularUser: idInterlocutor,
        }),
      });

      if (!res.ok) throw new Error('Bookmarking failed');

      return res;
    },
    onSuccess: (_, bookmark) => {
      client.setQueryData<DialogData>(
        dialogQueryOptions(client, idUser, idInterlocutor).queryKey,
        old => {
          if (!old) return;

          return {
            ...old,
            isBookmarked: bookmark,
          } satisfies DialogData;
        }
      );
    },
  });
}
