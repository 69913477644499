import { QueryClient, UseQueryOptions } from '@tanstack/react-query';
import { DialogData } from '../types/dialog';

export default function dialogQueryOptions(
  client: QueryClient,
  idUser: number,
  idInterlocutor: number
) {
  return {
    queryKey: ['dialog', { idUser, idInterlocutor }],
    queryFn: () =>
      client.getQueryData<DialogData>(['dialog', { idUser, idInterlocutor }]) ?? null,
  } satisfies UseQueryOptions;
}
