import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import DataTable from '@/components/ui/data-table';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import useOpenInAppMutation from '@/lib/mutations/openInApp';
import { girlsProfilesQueryOptions } from '@/lib/queries/girlsProfiles';
import { GirlProfile } from '@/lib/types/profiles';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import useSentPostMutation from './api/sendPost';

const girlsColumns: ColumnDef<GirlProfile>[] = [
  {
    id: 'avatar',
    header: 'Avatar',
    cell: ({ row }) => {
      const profile = row.original;

      return (
        <Avatar>
          <AvatarImage src={profile.personal.avatar_large} />
          <AvatarFallback>{profile.name}</AvatarFallback>
        </Avatar>
      );
    },
  },
  {
    accessorKey: 'name',
    header: () => <div className='text-center'>Name</div>,
  },
  {
    id: 'send',
    header: () => <div className='text-center'>Post</div>,
    cell: ({ row }) => {
      const { mutate, status, error } = useSentPostMutation();

      let text: string | undefined;

      if (status === 'idle') text = '🙂 Click me';
      else if (status === 'pending') text = 'Pending...';
      else if (status === 'success') text = '✅ Posted';
      else text = error?.message;

      return (
        <Button
          variant='outline'
          className='w-28 text-center'
          disabled={status === 'pending'}
          onClick={() => mutate(row.original.id)}
        >
          {text}
        </Button>
      );
    },
  },
  {
    id: 'goto',
    header: () => <div className='text-center'>Open</div>,
    cell: ({ row }) => {
      const { mutate, isPending } = useOpenInAppMutation(
        'https://talkytimes.com/news-feed'
      );

      return (
        <Button
          variant='outline'
          onClick={() => mutate(row.original.id)}
          disabled={isPending}
        >
          Go to
        </Button>
      );
    },
  },
];

export default function Newsfeed() {
  const { data } = useQuery(girlsProfilesQueryOptions);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <div>Newsfeed</div>
      </DialogTrigger>
      <DialogContent className='max-w-min'>
        <DialogHeader>
          <DialogTitle>Newsfeed</DialogTitle>
        </DialogHeader>
        <div className='max-h-[80vh] w-min overflow-y-auto text-center'>
          {data && <DataTable columns={girlsColumns} data={data} />}
        </div>
      </DialogContent>
    </Dialog>
  );
}
