import { MessageData } from '@/lib/types/dialog';
import { EyeNoneIcon, EyeOpenIcon, PlayIcon } from '@radix-ui/react-icons';

export function VideoMessageContent({
  videoMessageData,
}: {
  videoMessageData: Extract<MessageData, { type: 'video' }>;
}) {
  return (
    <>
      <div className='relative'>
        <img
          src={videoMessageData.content.url}
          className='h-44 w-44 rounded-xl border border-white'
        />
        <div className='absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white/30'>
          <PlayIcon className='h-10 w-10 text-white' />
        </div>
      </div>
      <div className='flex justify-between pt-1 text-xs'>
        <div className='text-muted-foreground'>Sent a video</div>
        {videoMessageData.content.isPurchased ? (
          <div className='flex items-center gap-1 rounded bg-green-100 px-1 py-0.5'>
            <EyeOpenIcon />
            seen
          </div>
        ) : (
          <div className='flex items-center gap-1 rounded bg-red-100 px-1 py-0.5'>
            <EyeNoneIcon />
            unseen
          </div>
        )}
      </div>
    </>
  );
}
