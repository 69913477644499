import { useQuery } from '@tanstack/react-query';

export type Restriction = {
  data: {
    lettersLeft: number;
    messagesLeft: number;
  };
};

export default function useRestrictionQuery(
  idUser: number,
  idInterlocutor: number
) {
  return useQuery({
    queryKey: [
      'restriction',
      {
        idUser,
        idInterlocutor,
      },
    ],
    queryFn: async () => {
      const params = new URLSearchParams({
        idUser: idUser.toString(),
        idRegularUser: idInterlocutor.toString(),
      });

      const res = await fetch('/api/v1/chat/restriction?' + params.toString());

      return res.json() as Promise<Restriction>;
    },
    staleTime: 0,
  });
}
