import { UseQueryOptions } from '@tanstack/react-query';

export default function getPhotoQueryOptions(
  idUser: number,
  idInterlocutor: number,
  preview: string
) {
  return {
    queryKey: ['photo', { idUser, idInterlocutor, url: preview }],
    queryFn: async () => {
      const params = new URLSearchParams({
        idUser: idUser.toString(),
        idInterlocutor: idInterlocutor.toString(),
        preview,
      });

      const res = await fetch(
        `/api/v1/gallery/operator/get-photo?` + params.toString()
      );

      return res.json() as Promise<{ data: { url: string } }>;
    },
    gcTime: 0,
  } satisfies UseQueryOptions;
}
