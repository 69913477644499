import addNewMessage from '@/lib/addNewMessage';
import dialogQueryOptions from '@/lib/queries/dialog';
import { Restriction } from '@/lib/queries/restriction';
import typingQueryOptions from '@/lib/queries/typing';
import { newEmailToast, newMessageToast } from '@/lib/toasts';
import { DialogData } from '@/lib/types/dialog';
import { EventData } from '@/lib/types/socket';
import {
  InfiniteData,
  QueryClient,
  useQueryClient,
} from '@tanstack/react-query';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import { useEffect } from 'react';
import { io } from 'socket.io-client';

function isEventData(msg: unknown): msg is EventData {
  if (!msg) return false;

  if (
    !(
      typeof msg === 'object' &&
      'result' in msg &&
      msg.result &&
      typeof msg.result === 'object'
    )
  )
    return false;

  return 'type' in msg.result && typeof msg.result.type === 'string';
}

function handleSocketEvent(client: QueryClient, data: EventData) {
  const type = data.result.type;

  if (type === 'MessageSent') {
    const message = data.result.data.message;
    const idInterlocutor =
      message.idUserTo !== data.idGirlUser
        ? message.idUserTo
        : message.idUserFrom;

    addNewMessage(client, data.idGirlUser, idInterlocutor, message);

    const msgType = message.type;
    const isTemporary =
      msgType === 'likephoto' ||
      msgType === 'like_newsfeed_post' ||
      msgType === 'wink' ||
      msgType === 'system';

    newMessageToast(data.idGirlUser, idInterlocutor, isTemporary);
  }

  if (type === 'email') {
    const message = data.result.email;
    const idInterlocutor =
      message.id_user_to !== data.idGirlUser
        ? message.id_user_to
        : message.id_user_from;

    newEmailToast(data.idGirlUser, idInterlocutor);
  }

  if (type === 'chat_DialogLimitChanged') {
    const newLimit = data.result.data;

    client.setQueryData<Restriction>(
      [
        'restriction',
        {
          idUser: data.idGirlUser,
          idInterlocutor: newLimit.idInterlocutor,
        },
      ],
      old => {
        if (!old) return;

        return {
          data: {
            lettersLeft: old.data.lettersLeft,
            messagesLeft: newLimit.limitLeft,
          },
        } satisfies Restriction;
      }
    );
  }

  if (type === 'platform_CorrespondenceLimitChanged') {
    const newLimit = data.result.data;

    client.setQueryData<Restriction>(
      [
        'restriction',
        {
          idUser: data.idGirlUser,
          idInterlocutor: newLimit.idInterlocutor,
        },
      ],
      old => {
        if (!old) return;

        return {
          data: {
            lettersLeft: newLimit.limitLeft,
            messagesLeft: old.data.messagesLeft,
          },
        } satisfies Restriction;
      }
    );
  }

  if (type === 'chat_MessageRead') {
    const { idInterlocutor, idMessage } = data.result.data;

    client.setQueryData<DialogData>(
      dialogQueryOptions(client, data.idGirlUser, idInterlocutor).queryKey,
      old => {
        if (!old) return;

        return {
          ...old,
          idInterlocutorLastReadMsg: idMessage,
        } satisfies DialogData;
      }
    );
  }

  if (type === 'chat_DialogTyping') {
    const idInterlocutor = data.result.data.idInterlocutor;

    client.setQueryData<{ id: string; at: number }>(
      typingQueryOptions(client, data.idGirlUser, idInterlocutor).queryKey,
      () => ({ id: data.result.id, at: Date.now() })
    );
  }

  if (type === 'chat_DialogHighlightingChanged') {
    const idUser = data.idGirlUser;
    const idInterlocutor = data.result.data.idInterlocutor;

    client.setQueryData<DialogData>(
      dialogQueryOptions(client, idUser, idInterlocutor).queryKey,
      old => {
        if (!old) return;

        return {
          ...old,
          highlightType: 'limit_reload',
        };
      }
    );

    client.setQueriesData<
      InfiniteData<
        {
          cursor: string;
          dialogs: {
            idUser: number;
            idInterlocutor: number;
          }[];
        },
        unknown
      >
    >({ queryKey: ['chats'] }, old => {
      if (!old) return;

      const topDialog = [{ idUser, idInterlocutor }];

      const newPages = old.pages.map((page, i) => {
        const filteredPage = page.dialogs.filter(
          dialog =>
            !(
              dialog.idUser === idUser &&
              dialog.idInterlocutor === idInterlocutor
            )
        );

        return {
          cursor: page.cursor,
          dialogs: i === 0 ? topDialog.concat(filteredPage) : filteredPage,
        };
      });

      return {
        pageParams: old.pageParams,
        pages: newPages,
      };
    });
  }
}

export const Route = createFileRoute('/_authenticated/_layout/_socket')({
  component: _Socket,
});

function _Socket() {
  const client = useQueryClient();

  useEffect(() => {
    const socket = io();

    socket.on('test', (data: unknown) => {
      if (isEventData(data)) handleSocketEvent(client, data);
    });

    return () => {
      socket.disconnect();
    };
  }, [client]);

  return <Outlet />;
}
