import { girlsProfilesQueryOptions } from '@/lib/queries/girlsProfiles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { girlsQueryOptions } from '@/lib/queries/girls';

export default function useDeleteGirlMutation() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async (girlId: number) => {
      const res = await fetch(`/api/v1/girls/${girlId}`, { method: 'DELETE' });

      if (!res.ok) throw new Error('Girl deleting failed');

      return res;
    },
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: girlsProfilesQueryOptions.queryKey });
      qc.invalidateQueries({ queryKey: girlsQueryOptions.queryKey });
    },
  });
}
