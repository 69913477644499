import Mailbox from '@/components/mailbox';
import OpenInAppDialog from '@/components/openInAppDialog';
import NavMailsIcon from '@/components/svg/navMailsIcon';
import NavMessagesIcon from '@/components/svg/navMessagesIcon';
import NavSearchIcon from '@/components/svg/navSearchIcon';
import NavStreamsIcon from '@/components/svg/navStreamsIcon';
import UserActions from '@/components/userActions';
import { newMessageToast } from '@/lib/toasts';
import { createFileRoute, Link, Outlet } from '@tanstack/react-router';
import { Toaster } from 'react-hot-toast';

export const Route = createFileRoute('/_authenticated/_layout')({
  component: MainLayout,
});

function MainLayout() {
  return (
    <div className='space-y-3'>
      <header className='border-b-2 bg-card'>
        <div className='container mx-auto grid grid-cols-11 grid-rows-1 items-center 2xl:p-0'>
          <nav className='col-span-5 col-start-4 justify-self-center'>
            <ul className='flex justify-center fill-muted-foreground text-center font-semibold text-muted-foreground'>
              <OpenInAppDialog url='https://talkytimes.com/search'>
                <li className='w-20 space-y-1 py-2 transition-colors duration-300 hover:bg-muted hover:fill-foreground hover:text-foreground'>
                  <div className='flex justify-center'>
                    <NavSearchIcon className='h-10 w-10 rounded-full bg-muted p-2' />
                  </div>
                  <div>Search</div>
                </li>
              </OpenInAppDialog>
              <Link to='/chat'>
                <li className='w-20 space-y-1 fill-primary py-2 text-primary transition-colors duration-300 hover:bg-muted hover:fill-foreground hover:text-foreground'>
                  <div className='flex justify-center'>
                    <NavMessagesIcon className='h-10 w-10 rounded-full bg-muted p-2' />
                  </div>
                  <div>Messages</div>
                </li>
              </Link>
              <Mailbox indicator>
                <li className='w-20 space-y-1 py-2 transition-colors duration-300 hover:bg-muted hover:fill-foreground hover:text-foreground'>
                  <div className='flex justify-center'>
                    <NavMailsIcon className='h-10 w-10 rounded-full bg-muted p-2' />
                  </div>
                  <div>Mailbox</div>
                </li>
              </Mailbox>

              <button
                onClick={() => {
                  const idUser = 81102701;
                  const idInterlocutor = 89573010;

                  newMessageToast(idUser, idInterlocutor);
                }}
              >
                <li className='w-20 space-y-1 py-2 text-muted-foreground opacity-50 transition-colors duration-300'>
                  <div className='flex justify-center'>
                    <NavStreamsIcon className='h-10 w-10 rounded-full bg-muted p-2' />
                  </div>
                  <div>Streams</div>
                </li>
              </button>
            </ul>
          </nav>
          <UserActions className='col-span-3 col-start-9 justify-self-end' />
        </div>
      </header>
      <Outlet />
      <Toaster position='bottom-right' />
    </div>
  );
}
