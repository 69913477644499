import addNewMessage from '@/lib/addNewMessage';
import { MessageData } from '@/lib/types/dialog';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Audio } from '../../types/audio';

export default function useSendAudioMutation() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async ({
      audio,
      idRegularUser,
      idUser,
    }: {
      audio: Audio;
      idRegularUser: number;
      idUser: number;
    }) => {
      const res = await fetch('/api/v1/chat/send/audio', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ idMedia: audio.id, idRegularUser, idUser }),
      });

      if (!res.ok) throw new Error('Audio sending failed');

      return res.json() as Promise<{ idMessage: number }>;
    },
    onSuccess: (res, { audio, idRegularUser, idUser }) => {
      const newMessage = {
        dateCreated: new Date().toISOString(),
        displayAttributes: [],
        id: res.idMessage,
        idUserFrom: idUser,
        idUserTo: idRegularUser,
        type: 'audio',
        content: {
          duration: audio.duration,
          id: audio.id,
          urlMp3: audio.url_mp3,
          urlOgg: audio.url_ogg,
        },
      } satisfies MessageData;

      addNewMessage(qc, idUser, idRegularUser, newMessage);
    },
  });
}
