import Mailbox from '@/components/mailbox';
import OpenInAppDialog from '@/components/openInAppDialog';
import { Separator } from '@/components/ui/separator';
import DialogList from '@/modules/dialogList';
import Newsfeed from '@/modules/newsfeed';
import SavedCleaner from '@/modules/savedCleaner';
import { createFileRoute, Link, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/_layout/_socket/chat')({
  component: Chat,
});

function Chat() {
  return (
    <main className='container mx-auto flex h-[85vh] items-stretch justify-center 2xl:p-0'>
      <DialogList />
      <Outlet />
      <div className='w-36 flex-none pl-4'>
        <div className='space-y-2 border py-2'>
          <h2 className='pl-2 text-lg font-bold'>My Activity</h2>
          <ul className='text-muted-foreground'>
            <li className='cursor-pointer p-2 transition-colors hover:bg-muted hover:text-foreground'>
              <OpenInAppDialog url='https://talkytimes.com/chat/:uid'>
                <div>Messages</div>
              </OpenInAppDialog>
            </li>
            <Mailbox>
              <li className='cursor-pointer p-2 transition-colors hover:bg-muted hover:text-foreground'>
                <div>Mailbox</div>
              </li>
            </Mailbox>
            <li className='cursor-pointer p-2 transition-colors hover:bg-muted hover:text-foreground'>
              <Newsfeed />
            </li>
            <li className='cursor-pointer p-2 transition-colors hover:bg-muted hover:text-foreground'>
              <OpenInAppDialog url='https://talkytimes.com/icebreakers'>
                <div>Icebreakers</div>
              </OpenInAppDialog>
            </li>
            <li className='cursor-pointer p-2 transition-colors hover:bg-muted hover:text-foreground'>
              <OpenInAppDialog url='https://talkytimes.com/icebreakers'>
                <div>Virtual gifts</div>
              </OpenInAppDialog>
            </li>
            <li className='px-2 py-1'>
              <Separator />
            </li>
            <li className='cursor-pointer p-2 transition-colors hover:bg-muted hover:text-foreground'>
              <SavedCleaner />
            </li>
            <li className='cursor-pointer p-2 transition-colors hover:bg-muted hover:text-foreground'>
              <Link to='/spammer'>
                <div className='font-semibold'>Spammer</div>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </main>
  );
}
