import { UseQueryOptions } from '@tanstack/react-query';

export type SpammerResponse = {
  girl_id: number;
  text: string | null;
  timeout: number;
  online_only: boolean;
  started: boolean;
  count: number;
};

const spammerQueryOptions = {
  queryKey: ['spammer'],
  queryFn: async () => {
    const res = await fetch('/api/v1/spammer');

    return res.json() as Promise<SpammerResponse[]>;
  },
  gcTime: 0,
} satisfies UseQueryOptions;

export default spammerQueryOptions;
