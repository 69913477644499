import { UseQueryOptions } from '@tanstack/react-query';

export type SpammerResponse = {
  girl_id: number;
  text: string | null;
  mails_timeout: number;
  mails_online_only: boolean;
  started: boolean;
  count: number;
  photos_ids: number[];
  videos_ids: number[];
};

const mailSpammerQueryOptions = {
  queryKey: ['mailSpammer'],
  queryFn: async () => {
    const res = await fetch('/api/v1/mail-spammer');

    return res.json() as Promise<SpammerResponse[]>;
  },
  gcTime: 0,
} satisfies UseQueryOptions;

export default mailSpammerQueryOptions;
