import { queryOptions } from '@tanstack/react-query';
import { createGirlLoginErrorToast } from '../toasts';
import { GirlProfile } from '../types/profiles';

type GirlProfileError = {
  title: string;
  email: string;
  idUser: number;
};

export const girlsProfilesQueryOptions = queryOptions({
  queryKey: ['girlsProfiles'],
  queryFn: async () => {
    const res = await fetch('/api/v1/private/personal-profile');

    if (res.status === 401) return null;

    const data = (await res.json()) as {
      errors: GirlProfileError[];
      girls_profiles: GirlProfile[];
    };

    for (const error of data.errors) {
      if (error.title === 'Bad girl credentials, no login')
        setTimeout(() => createGirlLoginErrorToast(error.email), 1000);
    }

    return data.girls_profiles;
  },
  staleTime: Infinity,
  gcTime: Infinity,
});
