import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';

export default function useCleanSavedMutation(idUser: number) {
  return useMutation({
    mutationFn: async (date: Date) => {
      const res = await fetch('/api/v1/chat/saved-cleaner', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          idUser,
          date: format(date, 'yyyy-MM-dd'),
        }),
      });

      if (!res.ok) throw new Error('Saved cleaning failed');

      return res;
    },
  });
}
