import { QueryClient, UseQueryOptions } from '@tanstack/react-query';

export default function typingQueryOptions(
  client: QueryClient,
  idUser: number,
  idInterlocutor: number
) {
  return {
    queryKey: ['typing', { idUser, idInterlocutor }],
    queryFn: async () => {
      const data = client.getQueryData<{ id: string; at: number } | null>([
        'typing',
        { idUser, idInterlocutor },
      ]);

      if (!data) return null;

      const diff = Date.now() - data.at;

      if (diff >= 5) return null;
      return data;
    },
    refetchInterval: 5 * 1000,
    gcTime: 0,
  } satisfies UseQueryOptions;
}
