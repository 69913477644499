import { QueryClient, UseQueryOptions } from '@tanstack/react-query';
import { ProfileData } from '../types/profiles';

export default function profileQueryOptions(
  client: QueryClient,
  idInterlocutor: number
) {
  return {
    queryKey: ['profile', idInterlocutor],
    queryFn: () =>
      client.getQueryData<ProfileData>(['profile', idInterlocutor]) ?? null,
  } satisfies UseQueryOptions;
}
