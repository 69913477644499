import { useInfiniteQuery } from '@tanstack/react-query';
import { MessageData } from '../types/dialog';

export default function useMessagesInfiniteQuery(
  idUser: number,
  idInterlocutor: number
) {
  return useInfiniteQuery({
    queryKey: ['messages', { idUser, idInterlocutor }],
    queryFn: async ({ pageParam }) => {
      const params = new URLSearchParams({
        idUser: idUser.toString(),
        idRegularUser: idInterlocutor.toString(),
        idLastMessage: pageParam.toString(),
        limit: '15',
      });

      const res = await fetch('/api/v1/chat/messages?' + params.toString());
      return res.json() as Promise<{
        messages: MessageData[];
      }>;
    },
    initialPageParam: 0,
    getNextPageParam: () => null,
    getPreviousPageParam: firstPage => firstPage.messages.at(0)?.id,
  });
}
