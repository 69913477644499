import { UseQueryOptions } from '@tanstack/react-query';

type LetterData = {
  data: {
    attachments: {
      images: Array<{
        id: string;
        is_paid: boolean;
        url_original: string;
        url_thumbnail: string;
      }>;
      videos: [];
    };
    content: string;
    conversation_index: string;
    conversation_remark_index: string;
    date_created: string;
    date_read: string;
    id: string;
    id_correspondence: string;
    id_template: string;
    id_user_from: string;
    id_user_to: string;
    is_deleted: string;
    is_paid: boolean;
    is_sent: string;
    is_starred: boolean;
    status: string;
    title: string;
  };
};

export default function letterQueryOptions(idUser: number, idLetter: number) {
  return {
    queryKey: ['letter', { idUser, idLetter }],
    queryFn: async () => {
      const params = new URLSearchParams({
        idUser: idUser.toString(),
        id: idLetter.toString(),
      });

      const res = await fetch(
        '/api/v1/correspondence/letter?' + params.toString()
      );

      return res.json() as Promise<LetterData>;
    },
  } satisfies UseQueryOptions;
}
