import {
  QueryClient,
  UseQueryOptions,
  useQueries,
} from '@tanstack/react-query';
import { ProfileData } from '../types/profiles';
import profileQueryOptions from './profile';

export default function useOnlinePollQueries(
  client: QueryClient,
  dialogsPages?: Array<{
    dialogs: Array<{ idUser: number; idInterlocutor: number }>;
  }>
) {
  return useQueries({
    queries:
      dialogsPages
        ?.filter(page => page.dialogs.length !== 0)
        .map(page => {
          const requestBody = page.dialogs.reduce(
            (acc, dialog) => {
              const interlocutorsIds = acc[dialog.idUser] ?? [];

              interlocutorsIds.push(dialog.idInterlocutor);
              acc[dialog.idUser] = interlocutorsIds;

              return acc;
            },
            {} as { [girlId: string]: number[] | undefined }
          );

          const initialData = page.dialogs.reduce(
            (acc, dialog) => {
              acc[dialog.idInterlocutor] = client.getQueryData<ProfileData>(
                profileQueryOptions(client, dialog.idInterlocutor).queryKey
              )?.is_online!;

              return acc;
            },
            {} as { [interlocutorId: string]: boolean }
          );

          return {
            queryKey: ['online', requestBody],
            queryFn: async () => {
              const res = await fetch('/api/v1/online', {
                method: 'POST',
                headers: {
                  'content-type': 'application/json',
                },
                body: JSON.stringify(requestBody),
              });

              return res.json() as Promise<{
                [interlocutorId: string]: boolean;
              }>;
            },
            initialData: initialData,
            staleTime: 60 * 1000,
            refetchInterval: 60 * 1000,
          } satisfies UseQueryOptions;
        }) ?? [],
    combine: results =>
      results.reduce(
        (acc, result) => {
          Object.assign(acc, result.data);
          return acc;
        },
        {} as { [interlocutorId: string]: boolean }
      ),
  });
}
