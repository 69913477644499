import { girlsProfilesQueryOptions } from '@/lib/queries/girlsProfiles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { girlsQueryOptions } from '@/lib/queries/girls';

export default function useEditGirlMutation() {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: async ({
      id,
      email,
      password,
    }: {
      id: number;
      email?: string;
      password?: string;
    }) => {
      const res = await fetch(`/api/v1/girls/${id}`, {
        method: 'PATCH',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (res.status === 403) throw new Error('Wrong credentials');

      if (res.status === 409) throw new Error('Email is already taken');

      if (!res.ok)
        throw new Error('Unexpected error while editing the girl. Try again');

      return res;
    },
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: girlsProfilesQueryOptions.queryKey });
      qc.invalidateQueries({ queryKey: girlsQueryOptions.queryKey });
    },
  });
}
