import LoadingSpinner from '@/components/svg/loadingSpinner';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import byCriteriaInfiniteQueryOptions, {
  CustomFilter,
} from '@/lib/queries/byCriteria';
import byPairsQueryOptions from '@/lib/queries/byPairs';
import { girlsProfilesQueryOptions } from '@/lib/queries/girlsProfiles';
import useOnlinePollQueries from '@/lib/queries/online';
import { CrossCircledIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons';
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useId, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import DialogListItem from './dialogNavItem';

function CategoryList({
  selectedTab,
  onlineOnly,
  hasMessages,
}: {
  selectedTab: string;
  onlineOnly: boolean;
  hasMessages: boolean;
}) {
  const qc = useQueryClient();

  const customFilter: CustomFilter[] = [];
  if (selectedTab) customFilter.push('notBlocked');
  if (hasMessages) customFilter.push('has10Messages');

  const { data, isFetching, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery(
      byCriteriaInfiniteQueryOptions(qc, selectedTab, onlineOnly, customFilter)
    );

  const onlineStatuses = useOnlinePollQueries(qc, data?.pages);

  const girlsProfiles = useQuery(girlsProfilesQueryOptions).data;

  const { ref } = useInView({
    threshold: 0.5,
    onChange: inView => {
      if (inView && !isFetching) fetchNextPage();
    },
  });

  // const pathParams = useParams({
  //   from: '/loginCheck/navigationLayout/socketRoute/chat/$dialogId',
  // });
  // const ids =
  //   pathParams && pathParams.dialogId
  //     ? pathParams.dialogId.split('_').map(id => Number(id))
  //     : undefined;

  let chats: JSX.Element[] | undefined;
  if (data && girlsProfiles) {
    chats = data.pages
      .flatMap(page => page.dialogs)
      .map((dialog, i, arr) => (
        <DialogListItem
          key={`${dialog.idUser}_${dialog.idInterlocutor}`}
          refProp={i === arr.length - 1 ? ref : undefined}
          idUser={dialog.idUser}
          idInterlocutor={dialog.idInterlocutor}
          isOnline={onlineStatuses[dialog.idInterlocutor]}
          selected={
            false
            // ids && ids[0] === dialog.idUser && ids[1] === dialog.idInterlocutor
          }
        />
      ));
  }

  if (
    data?.pages.at(-1)?.dialogs.length === 0 &&
    hasNextPage &&
    !isFetchingNextPage
  )
    fetchNextPage();

  return (
    <>
      {chats}
      {isFetchingNextPage && (
        <div className='absolute inset-x-1/2 inset-y-1/2 mx-auto'>
          <LoadingSpinner className='fixed h-10 w-10' />
        </div>
      )}
    </>
  );
}

function SearchList({ query }: { query: string }) {
  const qc = useQueryClient();

  const { data } = useQuery({
    ...byPairsQueryOptions(qc, Number(query)),
    gcTime: 0,
  });

  const onlineStatuses = useOnlinePollQueries(qc, data ? [data] : undefined);

  const girlsProfiles = useQuery(girlsProfilesQueryOptions).data;

  let chats: JSX.Element[] | undefined;
  if (data && girlsProfiles) {
    chats = data.dialogs.map(dialog => (
      <DialogListItem
        key={`${dialog.idUser}_${dialog.idInterlocutor}`}
        idUser={dialog.idUser}
        idInterlocutor={dialog.idInterlocutor}
        isOnline={onlineStatuses[dialog.idInterlocutor]}
      />
    ));
  }

  return chats;
}

export default function DialogList() {
  const [selectedTab, setSelectedTab] = useState<string>('active');
  const [isOnlineOnly, setIsOnlineOnly] = useState<boolean>(false);
  const [hasMessages, setHasMessages] = useState(false);

  const hasMessagesSwitchId = useId();
  const onlineSwitchId = useId();

  const [search, setSearch] = useState('');

  return (
    <div className='flex w-[29.5rem] flex-none flex-col items-stretch gap-2 border p-2'>
      <div className='relative'>
        <MagnifyingGlassIcon className='absolute inset-y-0 left-2 my-auto block h-5 w-5' />
        <Input
          placeholder='Search by ID'
          className='pl-8'
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        {search && (
          <Button
            variant='ghost'
            className='absolute inset-y-0 right-0 my-auto'
            onClick={() => setSearch('')}
          >
            <CrossCircledIcon className='h-5 w-5' />
          </Button>
        )}
      </div>
      <Tabs
        value={search ? '' : selectedTab}
        onValueChange={value => {
          setSelectedTab(value);
          setSearch('');
        }}
        className='w-full'
      >
        <TabsList className='w-full justify-between'>
          <TabsTrigger className='grow' value='active'>
            Active
          </TabsTrigger>
          <TabsTrigger className='grow' value='bookmarked'>
            Saved
          </TabsTrigger>
          <TabsTrigger className='grow' value='unanswered'>
            No answer
          </TabsTrigger>
          <TabsTrigger className='grow' value=''>
            All chats
          </TabsTrigger>
        </TabsList>
      </Tabs>
      <div className='flex justify-between px-0.5'>
        <div className='flex items-center gap-4'>
          <Label htmlFor={hasMessagesSwitchId}>Actions available</Label>
          <Switch
            id={hasMessagesSwitchId}
            checked={search ? false : hasMessages}
            onCheckedChange={setHasMessages}
          />
        </div>
        <div className='flex items-center gap-4'>
          <Label htmlFor={onlineSwitchId}>Online only</Label>
          <Switch
            id={onlineSwitchId}
            checked={search ? false : isOnlineOnly}
            onCheckedChange={setIsOnlineOnly}
          />
        </div>
      </div>
      <div className='relative overflow-y-auto'>
        {search ? (
          <SearchList query={search} />
        ) : (
          <CategoryList
            selectedTab={selectedTab}
            onlineOnly={isOnlineOnly}
            hasMessages={hasMessages}
          />
        )}
      </div>
    </div>
  );
}
