import useOpenInAppMutation from '@/lib/mutations/openInApp';
import { girlsProfilesQueryOptions } from '@/lib/queries/girlsProfiles';
import { GirlProfile } from '@/lib/types/profiles';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { ReactNode } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';
import { Button } from './ui/button';
import DataTable from './ui/data-table';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from './ui/dialog';

function getColumns(url: string) {
  const girlsColumns: ColumnDef<GirlProfile>[] = [
    {
      id: 'avatar',
      header: 'Avatar',
      cell: ({ row }) => {
        const profile = row.original;

        return (
          <Avatar>
            <AvatarImage src={profile.personal.avatar_large} />
            <AvatarFallback>{profile.name}</AvatarFallback>
          </Avatar>
        );
      },
    },
    {
      accessorKey: 'name',
      header: () => <div className='text-center'>Name</div>,
    },
    {
      accessorKey: 'personal.email',
      header: () => <div className='text-center'>Email</div>,
    },
    {
      id: 'goto',
      header: () => <div className='text-center'>Open</div>,
      cell: ({ row }) => {
        const { mutate, isPending } = useOpenInAppMutation(url);

        return (
          <Button
            variant='outline'
            onClick={() => mutate(row.original.id)}
            disabled={isPending}
          >
            Go to
          </Button>
        );
      },
    },
  ];

  return girlsColumns;
}

function OpenInAppDialogContent({ url }: { url: string }) {
  const { data } = useQuery(girlsProfilesQueryOptions);
  const columns = getColumns(url);

  return (
    <div className='max-h-[80vh] w-min overflow-y-auto text-center'>
      {data && <DataTable columns={columns} data={data} />}
    </div>
  );
}

export default function OpenInAppDialog({
  children,
  url,
}: {
  children: ReactNode;
  url: string;
}) {
  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className='max-w-min'>
        <DialogHeader>
          <DialogTitle>Select a profile</DialogTitle>
        </DialogHeader>
        <OpenInAppDialogContent url={url} />
      </DialogContent>
    </Dialog>
  );
}
