export default function NavSearchIcon({ className }: { className?: string }) {
  return (
    <svg
      viewBox='0 0 24 24'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M14.805 10.587a4.25 4.25 0 0 1 4.268 4.268 4.23 4.23 0 0 1-.654 2.277l2.921 2.94-1.318 1.318-2.96-2.912a4.266 4.266 0 0 1-2.257.645 4.25 4.25 0 0 1-4.268-4.268 4.25 4.25 0 0 1 4.268-4.268zm0 1.897a2.371 2.371 0 1 0 0 4.743 2.371 2.371 0 0 0 0-4.743zM9.588 3a3.794 3.794 0 0 1 3.016 6.098 6.025 6.025 0 0 0-2.153 1.395l-.863.094a3.794 3.794 0 1 1 0-7.587zM2 18.175v-1.897c0-2.943 1.954-4.238 7.588-4.748-.303.74-.948 2.49-.948 3.325 0 1.224.36 2.371.948 3.32H2z'></path>
    </svg>
  );
}
