import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { GirlProfile } from '@/lib/types/profiles';
import { DownloadIcon, PlayIcon, StopIcon } from '@radix-ui/react-icons';
import { Fragment } from 'react';
import { SpammerResponse } from './api/mailSpammer';
import useMailSpammerMutation from './api/patchMailSpammer';
import AttachedFiles from './attachedFiles';
import TextInput from './textInput';
import TimeoutInput from './timeoutInput';

const cols = [
  'Avatar',
  'Name',
  'Attached files',
  'Timeout, s',
  'Online only',
  'Start',
  'Sent',
  'History',
];

function OnlineOnlySwitch({ spammer }: { spammer?: SpammerResponse }) {
  const { mutate, isPending, variables } = useMailSpammerMutation(
    spammer?.girl_id ?? -1
  );

  if (!spammer) return;

  return (
    <div className='flex justify-center'>
      <Switch
        checked={isPending ? variables.online_only : spammer.mails_online_only}
        onCheckedChange={value => mutate({ online_only: value })}
        disabled={isPending}
      />
    </div>
  );
}

function StartButton({ spammer }: { spammer?: SpammerResponse }) {
  const { mutate, isPending, variables } = useMailSpammerMutation(
    spammer?.girl_id ?? -1
  );

  if (!spammer) return;

  const isStarted = isPending ? !!variables.started : spammer.started;

  return (
    <Button
      variant='outline'
      disabled={isPending || !spammer.text}
      onClick={() => mutate({ started: !isStarted })}
    >
      {isStarted ? <StopIcon /> : <PlayIcon />}
    </Button>
  );
}

export default function MailSpammerTable({
  data,
}: {
  data: Array<GirlProfile & { spammer?: SpammerResponse }>;
}) {
  return (
    <div className='rounded-md border'>
      <Table>
        <TableHeader>
          <TableRow>
            {cols.map(title => (
              <TableHead key={title} className='text-center'>
                {title}
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map(row => {
            return (
              <Fragment key={row.id}>
                <TableRow>
                  <TableCell className='flex justify-center'>
                    <Avatar>
                      <AvatarImage src={row.personal.avatar_large} />
                      <AvatarFallback>{row.name}</AvatarFallback>
                    </Avatar>
                  </TableCell>
                  <TableCell>{row.personal.name}</TableCell>
                  <TableCell>
                    {row.spammer && (
                      <AttachedFiles
                        data={{
                          ...row,
                          spammer: row.spammer,
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {row.spammer && <TimeoutInput spammer={row.spammer} />}
                  </TableCell>
                  <TableCell>
                    <OnlineOnlySwitch spammer={row.spammer} />
                  </TableCell>
                  <TableCell>
                    <StartButton spammer={row.spammer} />
                  </TableCell>
                  <TableCell className='text-center'>
                    {row.spammer?.count}
                  </TableCell>
                  <TableCell>
                    <Button variant='outline' disabled={!row.spammer}>
                      <a
                        href={`/api/v1/mail-spammer/mail_content.txt?girl_id=${row.spammer?.girl_id}`}
                        download
                      >
                        <DownloadIcon />
                      </a>
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={cols.length} className='bg-muted/30'>
                    {row.spammer && <TextInput spammer={row.spammer} />}
                  </TableCell>
                </TableRow>
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}
