import mailUrl from '@/assets/mail.svg';
import useOpenInAppMutation from '@/lib/mutations/openInApp';
import letterQueryOptions from '@/lib/queries/letter';
import { DialogData, MessageData } from '@/lib/types/dialog';
import { useQuery } from '@tanstack/react-query';
import { Button } from './ui/button';

function AttachmentIcon() {
  return (
    <svg
      viewBox='0 0 24 24'
      height='24px'
      width='24px'
      className='fill-primary'
    >
      <path d='M4 4h3l2-2h6l2 2h3a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2m8 3a5 5 0 0 0-5 5a5 5 0 0 0 5 5a5 5 0 0 0 5-5a5 5 0 0 0-5-5m0 2a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3Z'></path>
    </svg>
  );
}

export default function LetterMessage({
  dialogData,
  messageData,
}: {
  dialogData: DialogData;
  messageData: Extract<MessageData, { type: 'correspondence_letter' }>;
}) {
  const { data } = useQuery(
    letterQueryOptions(dialogData.idUser, messageData.content.idLetter)
  );

  const sortedIds = [dialogData.idUser, dialogData.idInterlocutor].sort(
    (a, b) => a - b
  );
  const { mutate, isPending } = useOpenInAppMutation(
    `https://talkytimes.com/mails/view/${sortedIds[0]}_${sortedIds[1]}`
  );

  const attachmentsLength =
    data &&
    data.data.attachments.images.length + data.data.attachments.videos.length;

  return (
    <div className='relative'>
      <img src={mailUrl} alt='' />
      <div className='absolute left-0 top-0 flex h-full w-full flex-col items-stretch justify-end gap-4 px-4 pb-6 pt-7 text-lg'>
        <div className='flex h-full flex-col px-6'>
          <div>{data?.data.title}</div>
          {attachmentsLength && attachmentsLength > 0 ? (
            <div className='mt-auto flex items-center justify-center'>
              <AttachmentIcon />
              {attachmentsLength}
            </div>
          ) : null}
        </div>
        <Button
          className='py-6 text-xl'
          onClick={() => mutate(dialogData.idUser)}
          disabled={isPending}
        >
          Read Mail
        </Button>
      </div>
    </div>
  );
}
