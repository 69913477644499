import { UseQueryOptions } from '@tanstack/react-query';

const subscriptionKeyQueryOptions = {
  queryKey: ['subKey'],
  queryFn: async () => {
    const res = await fetch('/api/v1/chat/get-voice-recognition-key');

    return res.json() as Promise<{ key: string }>;
  },
  gcTime: Infinity,
} satisfies UseQueryOptions;

export default subscriptionKeyQueryOptions;
