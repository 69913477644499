import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { z } from 'zod';

export const loginSchema = z.object({
  username: z
    .string()
    .min(1, { message: 'Username must be at least 1 characters.' }),
  password: z
    .string()
    .min(8, { message: 'Password must be at least 8 characters.' }),
});

export function useLoginMutation() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (values: z.infer<typeof loginSchema>) => {
      localStorage.setItem('username', values.username);

      return fetch('/api/v1/auth/login', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(values),
      });
    },
    onSuccess: res => {
      if (res.ok) {
        queryClient.clear();
        return navigate({ to: '/chat' });
      }

      if (res.status === 401)
        throw new Error(
          'Incorrect email address and/or password. Please try again'
        );

      throw new Error('Unknown error. Try later');
    },
  });
}
