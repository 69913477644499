import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import DataTable from '@/components/ui/data-table';
import { Dialog, DialogTrigger } from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { girlsProfilesQueryOptions } from '@/lib/queries/girlsProfiles';
import useDeleteGirlMutation from '@/modules/girlsSettings/api/deleteGirl';
import {
  DotsHorizontalIcon,
  ExclamationTriangleIcon,
} from '@radix-ui/react-icons';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { AddGirlDialog } from './addGirlDialog';
import { girlsQueryOptions } from '@/lib/queries/girls';
import EditGirlDialogContent from './editGirlDialogContent';
import { EntityProfile } from './types/entityProfile';

const girlsColumns: ColumnDef<EntityProfile>[] = [
  {
    accessorKey: 'personal.avatar_large',
    header: 'Avatar',
    cell: ({ row }) => {
      const profile = row.original.profile;

      return (
        <Avatar>
          <AvatarImage src={profile?.personal.avatar_large} />
          <AvatarFallback>{profile?.name}</AvatarFallback>
        </Avatar>
      );
    },
  },
  {
    accessorKey: 'profile.name',
    header: 'Name',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }) => {
      const entity = row.original;
      const { mutate } = useDeleteGirlMutation();

      const [open, setOpen] = useState(false);

      return (
        <Dialog open={open} onOpenChange={setOpen}>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className='flex items-center justify-end'>
                {!entity.profile ? (
                  <>
                    <ExclamationTriangleIcon className='h-5 w-5 text-amber-500' />
                    <Button variant='ghost' className='h-8 w-8 p-0'>
                      <span className='sr-only'>Open menu</span>
                      <DotsHorizontalIcon className='h-4 w-4' />
                    </Button>
                  </>
                ) : (
                  <Button variant='ghost' className='h-8 w-8 p-0'>
                    <span className='sr-only'>Open menu</span>
                    <DotsHorizontalIcon className='h-4 w-4' />
                  </Button>
                )}
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent align='end'>
              <DropdownMenuLabel>Actions</DropdownMenuLabel>
              <DialogTrigger asChild>
                <DropdownMenuItem>Edit</DropdownMenuItem>
              </DialogTrigger>
              <DropdownMenuItem
                className='text-red-600'
                onClick={() => mutate(entity.id)}
              >
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <EditGirlDialogContent entity={entity} setOpen={setOpen} />
        </Dialog>
      );
    },
  },
];

export default function GirlsSettings() {
  const { data: profilesData } = useQuery(girlsProfilesQueryOptions);
  const { data: entitiesData } = useQuery(girlsQueryOptions);

  if (profilesData && entitiesData) {
    const data = entitiesData.reduce((acc, { id, email, talkytimes_id }) => {
      const profile = profilesData.find(
        profile => profile.id === talkytimes_id
      );

      acc.push({ id, email, profile });
      return acc;
    }, [] as EntityProfile[]);

    return (
      <>
        <DataTable columns={girlsColumns} data={data} />
        <div className='flex justify-end'>
          <AddGirlDialog />
        </div>
      </>
    );
  }
}
