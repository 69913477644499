import { Button } from '@/components/ui/button';
import useOpenInAppMutation from '@/lib/mutations/openInApp';
import { useQuery } from '@tanstack/react-query';
import giftLimitQueryOptions from './api/queries/giftLimit';
import GiftsIcon from '@/components/svg/giftsIcon';

export default function Gifts({
  idUser,
  idInterlocutor,
}: {
  idUser: number;
  idInterlocutor: number;
}) {
  const { data, isPending } = useQuery(
    giftLimitQueryOptions(idUser, idInterlocutor)
  );
  const { mutate } = useOpenInAppMutation(
    `https://talkytimes.com/virtual-gifts/buy/${idInterlocutor}?from=chat`
  );

  return (
    <Button
      variant='outline'
      disabled={!data?.limit || isPending}
      onClick={() => mutate(idUser)}
    >
      <GiftsIcon className='h-6 w-6 fill-primary' />
      Gifts
    </Button>
  );
}
