import { UseQueryOptions } from '@tanstack/react-query';

export type SpammerResponse = {
  girl_id: number;
  timeout: number;
  started: boolean;
  count: number;
};

const searchSpammerQueryOptions = {
  queryKey: ['searchSpammer'],
  queryFn: async () => {
    const res = await fetch('/api/v1/search-spammer');

    return res.json() as Promise<SpammerResponse[]>;
  },
  gcTime: 0,
} satisfies UseQueryOptions;

export default searchSpammerQueryOptions;
