import microphoneIcon from '@/assets/microphone-solid.svg';
import { Button } from '@/components/ui/button';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
// @ts-expect-error
import createPonyfill from 'web-speech-cognitive-services/lib/SpeechServices';
import subscriptionKeyQueryOptions from './api/queries/subscriptionKey';

export default function RecognitionButton({
  onResult,
}: {
  onResult?: (e: any) => void;
}) {
  const { data } = useQuery(subscriptionKeyQueryOptions);

  const recognition = useMemo(() => {
    if (!data) return;

    const SpeechRecognition =
      // @ts-expect-error
      window.webkitSpeechRecognition ||
      createPonyfill({
        credentials: {
          region: 'westeurope',
          subscriptionKey: data.key,
        },
      }).SpeechRecognition;

    const recognition = new SpeechRecognition();
    recognition.lang = 'ru-RU';
    recognition.continuous = true;

    return recognition;
  }, [data]);

  const [recognitionState, setRecognitionState] = useState<
    'default' | 'prepare' | 'listen'
  >('default');

  useEffect(() => {
    if (!recognition) return;

    const handleAudiostart = () => setRecognitionState('listen');
    const handleEnd = () => setRecognitionState('default');

    recognition.addEventListener('result', onResult);
    recognition.addEventListener('audiostart', handleAudiostart);
    recognition.addEventListener('end', handleEnd);

    return () => {
      recognition.removeEventListener('result', onResult);
      recognition.removeEventListener('audiostart', handleAudiostart);
      recognition.removeEventListener('end', handleEnd);
    };
  }, [recognition, onResult, setRecognitionState]);

  const handleClick = () => {
    if (recognitionState === 'default') {
      recognition.start();
      setRecognitionState('prepare');
    }

    if (recognitionState === 'listen') {
      recognition.abort();
      setRecognitionState('default');
    }
  };

  useHotkeys('ctrl+\\', handleClick, { enableOnFormTags: true });

  if (!recognition) return;

  return (
    <Button
      variant={recognitionState === 'listen' ? 'destructive' : 'outline'}
      disabled={recognitionState === 'prepare'}
      onClick={handleClick}
    >
      <img className='h-5 w-5' src={microphoneIcon} alt='' />
    </Button>
  );
}
