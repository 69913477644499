import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { Textarea } from '@/components/ui/textarea';
import dialogQueryOptions from '@/lib/queries/dialog';
import { PaperPlaneIcon } from '@radix-ui/react-icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from '@tanstack/react-router';
import { useState } from 'react';
import useSendMessageMutation from './api/mutations/sendMessage';
import EmojiPicker from './emojiPicker';
import Gifts from './gifts';
import MediaPicker from './mediaPicker';
import RecognitionButton from './recognitionButton';
import StickerPicker from './stickerPicker';

const textLimit = 300;

export default function DialogInput({
  portalContainer,
}: {
  portalContainer: HTMLElement | null;
}) {
  const client = useQueryClient();

  const pathParams = useParams({ strict: false }).dialogId as string;
  const [idUser, idInterlocutor] = pathParams.split('_').map(id => Number(id));

  const [text, setText] = useState('');
  const [isFocused, setIsFocused] = useState(true);

  const { mutate, isPending } = useSendMessageMutation(
    client,
    idUser,
    idInterlocutor
  );

  const [checked, setChecked] = useState(
    () => !!localStorage.getItem('sendWithEnter')
  );

  const dialogData = useQuery(
    dialogQueryOptions(client, idUser, idInterlocutor)
  ).data;

  return (
    <fieldset disabled={dialogData?.isBlocked} className='space-y-2 p-2'>
      <div className='flex h-20 gap-2'>
        <div className='relative flex w-full'>
          <Textarea
            ref={elem => isFocused && elem?.focus()}
            key={'textarea'}
            placeholder='Type your message...'
            maxLength={textLimit}
            className='resize-none'
            value={text}
            onChange={e => setText(e.target.value)}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onKeyDown={e => {
              if (!checked) return;

              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();

                if (!text) return;

                if (isPending) return;
                mutate(text, { onSuccess: () => setText('') });
              }
            }}
          />
          <div className='absolute right-1 self-end text-sm text-muted-foreground'>
            {textLimit - text.length}
          </div>
        </div>
        <Button
          className='h-full gap-1 font-bold'
          disabled={!text || isPending}
          onClick={() => mutate(text, { onSuccess: () => setText('') })}
        >
          Send
          <PaperPlaneIcon />
        </Button>
      </div>
      <div className='flex items-center gap-3'>
        <EmojiPicker setText={setText} portalContainer={portalContainer} />
        <StickerPicker portalContainer={portalContainer} />
        <Gifts idUser={idUser} idInterlocutor={idInterlocutor} />
        <MediaPicker idUser={idUser} idInterlocutor={idInterlocutor} />
        <RecognitionButton
          onResult={(e: any) => {
            const lastIndex = e.results.length - 1;
            setText(value => value + e.results[lastIndex][0].transcript);
          }}
        />
        <div className='ml-auto flex items-center gap-1 rounded-md border px-3 py-2 text-sm font-medium'>
          <Switch
            checked={checked}
            onCheckedChange={value => {
              localStorage.setItem('sendWithEnter', value.toString());
              setChecked(value);
            }}
          />
          Send with Enter
        </div>
      </div>
    </fieldset>
  );
}
