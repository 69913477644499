import SentryFeedbackWidget from '@/components/sentryFeedbackWidget';
import { girlsProfilesQueryOptions } from '@/lib/queries/girlsProfiles';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated')({
  beforeLoad: async ({ context }) => {
    const requiredForLoginData = await context.queryClient.ensureQueryData(
      girlsProfilesQueryOptions
    );
    if (!requiredForLoginData)
      throw redirect({
        to: '/login',
      });
  },
  component: () => (
    <>
      <Outlet />
      <SentryFeedbackWidget />
    </>
  ),
});
