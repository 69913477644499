import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { User } from '@/lib/types/user';
import { zodResolver } from '@hookform/resolvers/zod';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import useEditEmployeeMutation from './api/editEmployee';

const editEmployeeFormSchema = z.object({
  username: z.string().trim(),
  password: z
    .string()
    .refine(value => value.length === 0 || value.length >= 8, {
      message: 'Password length must be at least 8 characters',
    })
    .refine(value => !/\s/.test(value), {
      message: 'Password must not include spaces',
    })
    .refine(
      value =>
        value.length === 0 || (/[a-zA-Z]/.test(value) && /\d/.test(value)),
      {
        message: 'Password must contain at least one letter and one number',
      }
    ),
});

export default function EditEmployeeDialog({ user }: { user: User }) {
  const { mutate, isPending, error } = useEditEmployeeMutation();

  const [open, setOpen] = useState(false);

  const form = useForm<z.infer<typeof editEmployeeFormSchema>>({
    resolver: zodResolver(editEmployeeFormSchema),
    defaultValues: {
      username: '',
      password: '',
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant='outline' disabled={user.is_admin}>
          Edit
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit employee</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(value => {
              const params: Parameters<typeof mutate>[0] = {
                id: user.id,
              };

              if (value.username) params.username = value.username;
              if (value.password) params.password = value.password;

              mutate(params, {
                onSuccess: () => {
                  setOpen(false);
                  form.reset();
                },
              });
            })}
          >
            <fieldset className='space-y-3' disabled={isPending}>
              <FormField
                control={form.control}
                name='username'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>New username</FormLabel>
                    <FormControl>
                      <Input placeholder={user.username} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='password'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>New password</FormLabel>
                    <FormControl>
                      <Input
                        type='password'
                        placeholder='(No changes)'
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {error && (
                <Alert variant='destructive' className='w-1/4'>
                  <ExclamationTriangleIcon className='h-4 w-4' />
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{error.message}</AlertDescription>
                </Alert>
              )}
              <DialogFooter>
                <Button type='submit' disabled={!form.formState.isDirty}>
                  Edit
                </Button>
              </DialogFooter>
            </fieldset>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
