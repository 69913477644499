import { Button } from '@/components/ui/button';
import useOpenInAppMutation from '@/lib/mutations/openInApp';
import { cn } from '@/lib/utils';

export default function MailsButton({
  className,
  idUser,
  idInterlocutor,
}: {
  className?: string;
  idUser: number;
  idInterlocutor: number;
}) {
  const sortedIds = [idUser, idInterlocutor].sort((a, b) => a - b);
  const { mutate, isPending } = useOpenInAppMutation(
    `https://talkytimes.com/mails/view/${sortedIds[0]}_${sortedIds[1]}`
  );

  return (
    <Button
      variant='outline'
      className={cn('gap-1', className)}
      disabled={isPending}
      onClick={() => mutate(idUser)}
    >
      <svg
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        className='h-6 w-6 fill-primary'
      >
        <path d='M19.2 5H4.8c-.99 0-1.791.787-1.791 1.75L3 17.25c0 .962.81 1.75 1.8 1.75h14.4c.99 0 1.8-.788 1.8-1.75V6.75C21 5.787 20.19 5 19.2 5zm0 3.5L12 12.875 4.8 8.5V6.75l7.2 4.375 7.2-4.375V8.5z'></path>
      </svg>
      Mails
    </Button>
  );
}
