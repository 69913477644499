import {
  InfiniteData,
  QueryClient,
  UndefinedInitialDataInfiniteOptions,
} from '@tanstack/react-query';
import { ByCriteria } from '../types/dialog';

export type CustomFilter = 'hasMessages' | 'has10Messages' | 'notBlocked';

async function fetchPost(
  cursor: string,
  criteria: string,
  isOnlineOnly: boolean,
  customFilter: CustomFilter[],
  client: QueryClient
) {
  const criterias: string[] = [];

  if (criteria) criterias.push(criteria);
  if (isOnlineOnly) criterias.push('online');

  const params = new URLSearchParams({
    cursor,
    limit: '20',
    criteria: criterias.toString(),
    customFilter: customFilter.toString(),
  });

  const res = await fetch(
    '/api/v1/chat/dialogs/by-criteria?' + params.toString()
  );
  const data = (await res.json()) as ByCriteria;

  for (const dialog of data.dialogs) {
    client.setQueryData(
      [
        'dialog',
        { idUser: dialog.idUser, idInterlocutor: dialog.idInterlocutor },
      ],
      dialog
    );
  }

  for (const profile of data.profiles) {
    client.setQueryData(['profile', profile.id], profile);
  }

  return {
    cursor: data.cursor,
    dialogs: data.dialogs.map(dialog => ({
      idUser: dialog.idUser,
      idInterlocutor: dialog.idInterlocutor,
    })),
  };
}

export default function byCriteriaInfiniteQueryOptions(
  client: QueryClient,
  criteria: string,
  isOnlineOnly: boolean,
  customFilter: CustomFilter[]
) {
  return {
    queryKey: ['chats', { selectedTab: criteria, isOnlineOnly, customFilter }],
    queryFn: async ({ pageParam }) =>
      fetchPost(pageParam, criteria, isOnlineOnly, customFilter, client),
    initialPageParam: '',
    getNextPageParam: lastPage => lastPage.cursor,
    gcTime: 0,
  } satisfies UndefinedInitialDataInfiniteOptions<
    {
      cursor: string;
      dialogs: {
        idUser: number;
        idInterlocutor: number;
      }[];
    },
    Error,
    InfiniteData<
      {
        cursor: string;
        dialogs: {
          idUser: number;
          idInterlocutor: number;
        }[];
      },
      unknown
    >,
    (
      | string
      | {
          selectedTab: string;
          isOnlineOnly: boolean;
          customFilter: CustomFilter[];
        }
    )[],
    string
  >;
}
