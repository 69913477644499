import { UseQueryOptions } from '@tanstack/react-query';
import { Audio } from '../../types/audio';

export default function audioQueryOptions(idUser: number) {
  return {
    queryKey: ['audio', idUser],
    queryFn: async () => {
      const res = await fetch(
        '/api/v1/upload/get-mail-media-gallery?idUser=' + idUser.toString()
      );

      return res.json() as Promise<{
        data: {
          audio: Audio[];
        };
      }>;
    },
    gcTime: 0,
  } satisfies UseQueryOptions;
}
