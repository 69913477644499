import InterlocutorStatus from '@/components/interlocutorStatus';
import OnlineIndicator from '@/components/onlineIndicator';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import useOpenInAppMutation from '@/lib/mutations/openInApp';
import { ProfileData } from '@/lib/types/profiles';
import { useQuery } from '@tanstack/react-query';
import BookmarkButton from './bookmarkButton';
import MailsButton from './mailsButton';
import PostsButton from './postsButton';

export default function DialogHeader({
  idUser,
  interlocutorProfileData,
}: {
  idUser: number;
  interlocutorProfileData: ProfileData;
}) {
  const idInterlocutor = interlocutorProfileData.id;

  const requestBody = { [idUser.toString()]: [idInterlocutor] };
  const { data } = useQuery({
    queryKey: ['online', requestBody],
    queryFn: async () => {
      const res = await fetch('/api/v1/online', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      return res.json() as Promise<{ [interlocutorId: string]: boolean }>;
    },
    initialData: {
      [idInterlocutor.toString()]: interlocutorProfileData.is_online,
    },
    refetchInterval: 60 * 1000,
    staleTime: 60 * 1000,
  });

  const sortedIds = [idUser, idInterlocutor].sort((a, b) => a - b);
  const { mutate, isPending } = useOpenInAppMutation(
    `https://talkytimes.com/chat/${sortedIds[0]}_${sortedIds[1]}`
  );

  return (
    <div className='flex items-center gap-2 p-2'>
      <div className='relative'>
        <Avatar className='h-12 w-12'>
          <AvatarImage
            src={interlocutorProfileData?.personal.avatar_large}
            alt="Interlocutor's avatar"
          />
          <AvatarFallback>{interlocutorProfileData?.name}</AvatarFallback>
        </Avatar>
        <OnlineIndicator
          className='absolute -right-1 bottom-0'
          isOnline={data[idInterlocutor]}
        />
      </div>
      <div>
        <div className='text-lg font-semibold text-card-foreground/90'>
          {interlocutorProfileData?.name},{' '}
          {interlocutorProfileData?.personal.age}
        </div>
        <InterlocutorStatus
          idUser={idUser}
          interlocutorProfileData={interlocutorProfileData}
          isOnline={data[idInterlocutor]}
        />
      </div>
      <div className='ml-auto'></div>
      <BookmarkButton idUser={idUser} idInterlocutor={idInterlocutor} />
      <PostsButton
        idUser={idUser}
        idInterlocutor={idInterlocutor}
        className='font-semibold'
      />
      <MailsButton
        idUser={idUser}
        idInterlocutor={idInterlocutor}
        className='font-semibold'
      />
      <Button
        variant='outline'
        disabled={isPending}
        className='font-semibold'
        onClick={() => mutate(idUser)}
      >
        View in App
      </Button>
    </div>
  );
}
