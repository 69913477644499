import { useMutation, useQueryClient } from '@tanstack/react-query';
import dialogQueryOptions from '../queries/dialog';
import { DialogData } from '../types/dialog';

export default function useMessageReadMutation(
  idUser: number,
  idInterlocutor: number
) {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (idMessage: number) => {
      const res = await fetch('/api/v1/chat/message/read', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({ idUser, idMessage }),
      });

      return res;
    },
    onSuccess: (_, idMessage) => {
      client.setQueryData<DialogData>(
        dialogQueryOptions(client, idUser, idInterlocutor).queryKey,
        old => {
          if (!old) return;

          return {
            ...old,
            idLastReadMsg: idMessage,
          } satisfies DialogData;
        }
      );
    },
  });
}
