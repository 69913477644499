import { useMutation } from '@tanstack/react-query';

type GirlCookies = {
  cookie: string;
  refresh_token: string;
};

type OpenInAppMessage = {
  type: 'openInApp';
  url: string;
  cookies: string;
  refreshToken: string;
  idUser: number;
};

export default function useOpenInAppMutation(url: string) {
  return useMutation({
    mutationFn: async (idUser: number) =>
      fetch('/api/v1/get-cookies?idUser=' + idUser.toString()).then(res =>
        res.json()
      ) as Promise<GirlCookies>,
    onSuccess: (res, idUser) => {
      const message = {
        type: 'openInApp',
        url,
        cookies: res.cookie,
        refreshToken: res.refresh_token,
        idUser,
      } satisfies OpenInAppMessage;

      window.postMessage(message);
    },
  });
}
