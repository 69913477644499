import { UseQueryOptions } from '@tanstack/react-query';

export default function restrictionsQueryOptions(
  idUser: number,
  idInterlocutor: number
) {
  return {
    queryKey: ['restrictions', { idUser, idInterlocutor }],
    queryFn: async () => {
      const params = new URLSearchParams({
        idUser: idUser.toString(),
        idInterlocutor: idInterlocutor.toString(),
      });

      const res = await fetch(
        '/api/v1/chat/dialog/restrictions?' + params.toString(),
        { method: 'POST' }
      );

      return res.json() as Promise<{
        allowSendPost: boolean;
        photoForbiddenTags: string[];
        postPhotoForbiddenTags: string[];
        postVideoForbiddenTags: string[];
        videoForbiddenTags: string[];
      }>;
    },
  } satisfies UseQueryOptions;
}
