import useOpenInAppMutation from '@/lib/mutations/openInApp';
import { DialogData, MessageData } from '@/lib/types/dialog';
import { Button } from './ui/button';

export default function UnsupportedMessage({
  dialogData,
  messageData,
}: {
  dialogData: DialogData;
  messageData: MessageData;
}) {
  const dialogIds = [dialogData.idUser, dialogData.idInterlocutor].sort();
  const { mutate, isPending } = useOpenInAppMutation(
    `https://talkytimes.com/chat/${dialogIds[0]}_${dialogIds[1]}`
  );

  return (
    <div className='space-y-2'>
      <div>Unsupported message type: {messageData.type}</div>
      <div className='flex justify-center'>
        <Button onClick={() => mutate(dialogData.idUser)} disabled={isPending}>
          View in app
        </Button>
      </div>
    </div>
  );
}
