export default function DeclinedMediaIcon({
  className,
}: {
  className?: string;
}) {
  return (
    <svg
      viewBox='0 0 20 20'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path d='M10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.733 8.333 8.333 8.333S18.333 14.6 18.333 10 14.6 1.667 10 1.667zM3.333 10A6.665 6.665 0 0 1 10 3.333c1.542 0 2.958.525 4.083 1.409l-9.341 9.341A6.585 6.585 0 0 1 3.333 10zM10 16.667a6.585 6.585 0 0 1-4.083-1.409l9.341-9.341A6.585 6.585 0 0 1 16.667 10 6.665 6.665 0 0 1 10 16.667z'></path>
    </svg>
  );
}
