import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import DataTable from '@/components/ui/data-table';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { girlsProfilesQueryOptions } from '@/lib/queries/girlsProfiles';
import { GirlProfile } from '@/lib/types/profiles';
import { cn } from '@/lib/utils';
import { CalendarIcon } from '@radix-ui/react-icons';
import { useQuery } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';
import { format, sub } from 'date-fns';
import { useState } from 'react';
import useCleanSavedMutation from './api/cleanSaved';

function defaultDate() {
  return sub(new Date(), { months: 1 });
}

const girlsColumns: ColumnDef<{
  profile: GirlProfile;
  date: Date;
  setDates: React.Dispatch<
    React.SetStateAction<Record<number, Date | undefined>>
  >;
}>[] = [
  {
    id: 'avatar',
    header: () => <div className='text-center'>Avatar</div>,
    cell: ({ row }) => {
      const data = row.original;

      return (
        <Avatar>
          <AvatarImage src={data.profile.personal.avatar_large} />
          <AvatarFallback>{data.profile.name}</AvatarFallback>
        </Avatar>
      );
    },
  },
  {
    accessorKey: 'profile.name',
    header: () => <div className='text-center'>Name</div>,
  },
  {
    id: 'date',
    header: () => <div className='text-center'>Before date</div>,
    cell: ({ row }) => {
      const { date, setDates } = row.original;
      const [month, setMonth] = useState(date);

      return (
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={'outline'}
              className={cn(
                'w-[280px] justify-start text-left font-normal',
                !date && 'text-muted-foreground'
              )}
            >
              <CalendarIcon className='mr-2 h-4 w-4' />
              {format(date, 'PPP')}
            </Button>
          </PopoverTrigger>
          <PopoverContent className='w-auto p-0'>
            <Calendar
              mode='single'
              month={month}
              onMonthChange={setMonth}
              selected={date}
              onSelect={(_, selectedDay) => {
                if (selectedDay.getTime() === date.getTime()) return;

                setDates(dates => ({
                  ...dates,
                  [row.original.profile.id]: selectedDay,
                }));
              }}
              disabled={date => date > sub(new Date(), { months: 1 })}
              required
              initialFocus
            />
          </PopoverContent>
        </Popover>
      );
    },
  },
  {
    id: 'action',
    cell: ({ row }) => {
      const data = row.original;
      const { mutate, isPending, isError, isSuccess, isIdle } =
        useCleanSavedMutation(data.profile.id);

      return (
        <Button
          variant={isSuccess ? 'outline' : 'destructive'}
          className='w-20 px-2'
          disabled={isPending || isSuccess}
          onClick={() => mutate(data.date)}
        >
          {(isIdle || isPending) && 'Clean'}
          {isSuccess && 'Cleaned'}
          {isError && 'Error'}
        </Button>
      );
    },
  },
];

export default function SavedCleaner() {
  const { data: girlsProfiles } = useQuery(girlsProfilesQueryOptions);
  const [dates, setDates] = useState<Record<number, Date | undefined>>({});

  const data = girlsProfiles?.map(girlProfile => {
    const date = dates[girlProfile.id];
    return {
      profile: girlProfile,
      date: date ?? defaultDate(),
      setDates,
    };
  });

  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className='font-semibold'>Saved Cleaner</div>
      </DialogTrigger>
      <DialogContent className='max-w-min'>
        <DialogHeader>
          <DialogTitle>Saved Cleaner</DialogTitle>
        </DialogHeader>
        <div className='max-h-[80vh] w-min overflow-y-auto text-center'>
          {data && <DataTable columns={girlsColumns} data={data} />}
        </div>
      </DialogContent>
    </Dialog>
  );
}
