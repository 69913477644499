import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/_layout/_socket/chat/')({
  component: ChatIndex,
});

function ChatIndex() {
  return (
    <div className='mx-auto my-auto'>Select a chat to start messaging</div>
  );
}
