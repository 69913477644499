import { Skeleton } from '@/components/ui/skeleton';

export default function ItemSkeleton() {
  return (
    <div className='flex w-full items-center gap-2 border-y p-2'>
      <div className='flex items-center gap-0.5 rounded-lg border bg-muted p-1'>
        <Skeleton className='h-6 w-20' />
        <Skeleton className='h-10 w-10 rounded-full' />
      </div>

      <div>
        <Skeleton className='h-12 w-12 rounded-full' />
      </div>

      <div className='w-full'>
        <Skeleton className='h-11 w-full' />
      </div>
    </div>
  );
}
