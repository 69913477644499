import { UseQueryOptions } from '@tanstack/react-query';

export default function giftLimitQueryOptions(
  idUser: number,
  idUserTo: number
) {
  return {
    queryKey: ['giftLimit', { idUser, idUserTo }],
    queryFn: async () => {
      const params = new URLSearchParams({
        idUser: idUser.toString(),
        idUserTo: idUserTo.toString(),
      });

      const res = await fetch(
        '/api/v1/chat/virtual-gift/limit/get?' + params.toString()
      );

      return res.json() as Promise<{ limit: number }>;
    },
    gcTime: 0,
  } satisfies UseQueryOptions;
}
