import { girlsQueryOptions } from '@/lib/queries/girls';
import { girlsProfilesQueryOptions } from '@/lib/queries/girlsProfiles';
import { useQuery } from '@tanstack/react-query';
import mailSpammerQueryOptions from './api/mailSpammer';
import MailSpammerTable from './mailSpammerTable';

export default function MailSpammer() {
  const { data: girlsData } = useQuery(girlsQueryOptions);
  const { data: girlsProfilesData } = useQuery(girlsProfilesQueryOptions);
  const { data: spammerData } = useQuery(mailSpammerQueryOptions);

  if (!girlsData || !girlsProfilesData || !spammerData) return;

  const data = girlsProfilesData.map(girlProfile => {
    const girl = girlsData.find(girl => girlProfile.id === girl.talkytimes_id);

    return {
      ...girlProfile,
      spammer: spammerData.find(spammer => spammer.girl_id === girl?.id),
    };
  });

  if (data) return <MailSpammerTable data={data} />;
}
