import { UseQueryOptions } from '@tanstack/react-query';

const mailCountersQueryOptions = {
  queryKey: ['mailCounters'],
  queryFn: async () => {
    const res = await fetch('/api/v1/mails/mail_counters');

    return res.json() as Promise<{
      [id: string]: number | undefined;
      totalCount: number;
    }>;
  },
  staleTime: 60 * 1000,
  refetchInterval: 60 * 1000,
} satisfies UseQueryOptions;

export default mailCountersQueryOptions;
