import getPhotoQueryOptions from '@/lib/queries/getPhoto';
import { DialogData, MessageData } from '@/lib/types/dialog';
import { cn } from '@/lib/utils';
import { UseQueryOptions, useQueries } from '@tanstack/react-query';
import { useState } from 'react';
import PhotoCarousel from './photoCarousel';

function PhotoGrid({
  images,
  setIndex,
}: {
  images: Array<{ id: number; url: string }>;
  setIndex: (value: number | null) => void;
}) {
  switch (images.length) {
    case 1:
      return (
        <img
          key={images[0].id}
          src={images[0].url}
          className='h-44 w-44 cursor-pointer rounded-l-xl rounded-tr-xl border border-white'
          onClick={() => setIndex(0)}
        />
      );
    case 2:
      return (
        <div className='grid h-52 w-52 grid-cols-2 grid-rows-2 gap-[1px] overflow-hidden rounded-l-xl rounded-tr-xl border border-background bg-background'>
          {images.map((photo, i) => (
            <img
              key={photo.id}
              src={photo.url}
              className='row-span-2 h-full w-full cursor-pointer object-cover'
              onClick={() => setIndex(i)}
            />
          ))}
        </div>
      );
    case 3:
    case 4:
      return (
        <div className='grid h-52 w-52 grid-cols-2 grid-rows-2 gap-[1px] overflow-hidden rounded-l-xl rounded-tr-xl border border-background bg-background'>
          {images.map((photo, i) => (
            <img
              key={photo.id}
              src={photo.url}
              className={cn(
                'h-full w-full cursor-pointer object-cover',
                images.length === 3 && i === 0 ? 'row-span-2' : 'row-span-1'
              )}
              onClick={() => setIndex(i)}
            />
          ))}
        </div>
      );
    case 5:
    case 6:
      return (
        <div className='grid h-36 w-56 grid-cols-3 grid-rows-2 gap-[1px] overflow-hidden rounded-l-xl rounded-tr-xl border border-background bg-background'>
          {images.map((photo, i) => (
            <img
              key={photo.id}
              src={photo.url}
              className={cn(
                'h-full w-full cursor-pointer object-cover',
                images.length === 5 && i === 0 ? 'row-span-2' : 'row-span-1'
              )}
              onClick={() => setIndex(i)}
            />
          ))}
        </div>
      );
  }
}

export default function PhotoMessage({
  dialogData,
  messageData,
}: {
  dialogData: DialogData;
  messageData: Extract<
    MessageData,
    { type: 'photo' } | { type: 'photo_batch' }
  >;
}) {
  const [index, setIndex] = useState<number | null>(null);

  const images =
    messageData.type === 'photo'
      ? [{ id: messageData.content.id, url: messageData.content.url }]
      : messageData.content.photos;

  const { data, isPending } = useQueries({
    queries: images.map(
      image =>
        ({
          ...getPhotoQueryOptions(
            dialogData.idUser,
            dialogData.idInterlocutor,
            image.url
          ),
          enabled: index !== null,
        }) satisfies UseQueryOptions
    ),
    combine: results => ({
      data: results.map(result => result.data),
      isPending: results.some(result => result.isPending),
    }),
  });

  return (
    <>
      <PhotoGrid images={images} setIndex={setIndex} />
      {!isPending && (
        <PhotoCarousel
          key='carousel'
          images={images.map((image, i) => ({
            id: image.id,
            url: data[i]?.data.url!,
          }))}
          index={index}
          setIndex={setIndex}
        />
      )}
    </>
  );
}
