import mediaIconUrl from '@/assets/mediaIcon.svg';
import PhotoCarousel from '@/components/photoCarousel';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import useGalleryInfiniteQuery from '@/lib/queries/gallery';
import { PhotoData, VideoData } from '@/lib/types/media';
import { GirlProfile } from '@/lib/types/profiles';
import { cn } from '@/lib/utils';
import {
  CameraIcon,
  PaperPlaneIcon,
  SizeIcon,
  VideoIcon,
} from '@radix-ui/react-icons';
import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { SpammerResponse } from './api/mailSpammer';
import useMailSpammerMutation from './api/patchMailSpammer';

const attachPhotoLimit = 10;
const attachVideoLimit = 10;

function eqArrays<T>(x: T[], y: T[]) {
  if (x.length !== y.length) return false;

  return x.every(x => y.includes(x));
}

const MediaView = forwardRef<
  HTMLDivElement,
  {
    data: PhotoData | VideoData;
    attached: boolean;
    onClick: () => void | undefined;
    onButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  }
>(({ data, attached, onClick, onButtonClick }, ref) => {
  const erotic = data.tags.some(tag => tag.code === 'erotic');

  const approved =
    data.status.code === 'approved' || data.status.code === 'approved_by_ai';

  const available = !erotic && approved;

  const url = 'idPhoto' in data ? data.urls.urlPreview : data.urls.urlThumbnail;

  return (
    <div
      ref={ref}
      className='relative h-44 w-44'
      onClick={available ? onClick : undefined}
    >
      <img
        src={url}
        alt=''
        className={cn(
          'h-full w-full rounded-md bg-green-100',
          !available && 'opacity-60',
          attached && 'border-4 border-green-500 p-2'
        )}
      />

      {'idPhoto' in data && (
        <div className='absolute left-0 top-0 flex h-full w-full items-center justify-center'>
          <button
            className='rounded-full border border-white p-1'
            onClick={e => {
              e.stopPropagation();
              if (onButtonClick) onButtonClick(e);
            }}
          >
            <SizeIcon className='h-8 w-8 text-white' />
          </button>
        </div>
      )}
    </div>
  );
});

function PhotoGrid({ setOpen }: { setOpen: (open: boolean) => void }) {
  const ctx = useContext(DataContext);

  const [index, setIndex] = useState<number | null>(null);

  const { data, fetchNextPage, isFetching } = useGalleryInfiniteQuery(
    'photo',
    ctx.id
  );

  const { mutate, isPending } = useMailSpammerMutation(ctx.spammer.girl_id);

  const [attached, setAttached] = useState(new Set(ctx.spammer.photos_ids));
  useEffect(() => {
    setAttached(new Set(ctx.spammer.photos_ids));
  }, [ctx.spammer.photos_ids]);

  const { ref } = useInView({
    threshold: 0.8,
    onChange: inView => {
      if (inView && !isFetching) fetchNextPage();
    },
  });

  const photosData = data?.pages.flatMap(page => page.photos);

  const photos = photosData?.map((photo, i, arr) => (
    <MediaView
      key={photo.idPhoto}
      ref={i === arr.length - 1 ? ref : undefined}
      data={{
        ...photo,
      }}
      attached={attached.has(photo.idPhoto)}
      onClick={() => {
        if (!attached.has(photo.idPhoto)) {
          if (attached.size === attachPhotoLimit) return;

          attached.add(photo.idPhoto);
          setAttached(new Set(attached));
        } else {
          attached.delete(photo.idPhoto);
          setAttached(new Set(attached));
        }
      }}
      onButtonClick={() => setIndex(i)}
    />
  ));

  return (
    <div className='space-y-2'>
      <div className='grid h-[60vh] grid-cols-5 gap-4 overflow-y-auto'>
        {photos}
      </div>
      <div className='flex justify-end gap-4'>
        <Button
          variant='destructive'
          className='gap-1 font-bold'
          disabled={!attached.size || isPending}
          onClick={() => {
            mutate(
              { photos_ids: [] },
              { onSuccess: () => setAttached(new Set()) }
            );
          }}
        >
          Clear photos
        </Button>
        <Button
          className='gap-1 font-bold'
          disabled={
            eqArrays([...attached], ctx.spammer.photos_ids) || isPending
          }
          onClick={() => {
            mutate(
              { photos_ids: [...attached] },
              { onSuccess: () => setOpen(false) }
            );
          }}
        >
          Pick
          <PaperPlaneIcon />
        </Button>
      </div>
      {photosData && (
        <PhotoCarousel
          index={index}
          setIndex={setIndex}
          images={photosData.map(photo => ({
            id: photo.idPhoto,
            url: photo.urls.urlStandard,
          }))}
        />
      )}
    </div>
  );
}

function VideoGrid({ setOpen }: { setOpen: (open: boolean) => void }) {
  const ctx = useContext(DataContext);

  const { data, fetchNextPage, isFetching } = useGalleryInfiniteQuery(
    'video',
    ctx.id
  );

  const { mutate, isPending } = useMailSpammerMutation(ctx.spammer.girl_id);

  const [attached, setAttached] = useState(new Set(ctx.spammer.videos_ids));
  useEffect(() => {
    setAttached(new Set(ctx.spammer.videos_ids));
  }, [ctx.spammer.videos_ids]);

  const { ref } = useInView({
    threshold: 0.8,
    onChange: inView => {
      if (inView && !isFetching) fetchNextPage();
    },
  });

  const videosData = data?.pages.flatMap(page => page.videos);

  const videos = videosData?.map((video, i, arr) => (
    <MediaView
      key={video.idVideo}
      ref={i === arr.length - 1 ? ref : undefined}
      data={{
        ...video,
      }}
      attached={attached.has(video.idVideo)}
      onClick={() => {
        if (!attached.has(video.idVideo)) {
          if (attached.size === attachVideoLimit) return;

          attached.add(video.idVideo);
          setAttached(new Set(attached));
        } else {
          attached.delete(video.idVideo);
          setAttached(new Set(attached));
        }
      }}
    />
  ));

  return (
    <div className='space-y-2'>
      <div className='grid h-[60vh] grid-cols-5 gap-4 overflow-y-auto'>
        {videos}
      </div>
      <div className='flex justify-end gap-4'>
        <Button
          variant='destructive'
          className='gap-1 font-bold'
          disabled={!attached.size || isPending}
          onClick={() => {
            mutate(
              { videos_ids: [] },
              { onSuccess: () => setAttached(new Set()) }
            );
          }}
        >
          Clear videos
        </Button>
        <Button
          className='gap-1 font-bold'
          disabled={
            eqArrays([...attached], ctx.spammer.videos_ids) || isPending
          }
          onClick={() =>
            mutate(
              { videos_ids: [...attached] },
              { onSuccess: () => setOpen(false) }
            )
          }
        >
          Send
          <PaperPlaneIcon />
        </Button>
      </div>
    </div>
  );
}

function MediaPicker() {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className='' variant='outline'>
          <img src={mediaIconUrl} alt='' />
          Media
        </Button>
      </DialogTrigger>
      <DialogContent className='max-w-screen-lg'>
        <DialogHeader>
          <DialogTitle>Media Gallery</DialogTitle>
        </DialogHeader>
        <Tabs defaultValue='photo'>
          <div className='flex justify-center'>
            <TabsList>
              <TabsTrigger className='text-xl' value='photo'>
                Photo
              </TabsTrigger>
              <TabsTrigger className='text-xl' value='video'>
                Video
              </TabsTrigger>
            </TabsList>
          </div>
          <TabsContent value='photo'>
            <PhotoGrid setOpen={setOpen} />
          </TabsContent>
          <TabsContent value='video'>
            <VideoGrid setOpen={setOpen} />
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
}

type DataContextProps = GirlProfile & { spammer: SpammerResponse };

const DataContext = createContext<DataContextProps>({} as DataContextProps);

export default function AttachedFiles({ data }: { data: DataContextProps }) {
  return (
    <div className='flex w-96 items-center gap-3'>
      <div className='flex items-center gap-1'>
        <CameraIcon className='h-6 w-6' />
        {data.spammer.photos_ids.length}/10
      </div>
      <div className='flex items-center gap-1'>
        <VideoIcon className='h-6 w-6' />
        {data.spammer.videos_ids.length}/10
      </div>
      <div className='ml-auto'>
        <DataContext.Provider value={data}>
          <MediaPicker />
        </DataContext.Provider>
      </div>
    </div>
  );
}
