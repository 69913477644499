import useChatReadMutation from '@/lib/mutations/chatRead';
import useMessageReadMutation from '@/lib/mutations/messageRead';
import byPairsQueryOptions from '@/lib/queries/byPairs';
import dialogQueryOptions from '@/lib/queries/dialog';
import useMessagesInfiniteQuery from '@/lib/queries/messages';
import profileQueryOptions from '@/lib/queries/profile';
import { dismissToast } from '@/lib/toasts';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import Message from './message';
import RestrictionInfo from './restrictionInfo';

export default function DialogView({
  idUser,
  idInterlocutor,
}: {
  idUser: number;
  idInterlocutor: number;
}) {
  const client = useQueryClient();

  const dialogData = useQuery(
    dialogQueryOptions(client, idUser, idInterlocutor)
  ).data;
  const interlocutorProfileData = useQuery(
    profileQueryOptions(client, idInterlocutor)
  ).data;

  useQuery({
    ...byPairsQueryOptions(client, idInterlocutor, idUser),
    enabled: !(dialogData && interlocutorProfileData),
  });

  const { data, isFetching, fetchPreviousPage } = useMessagesInfiniteQuery(
    idUser,
    idInterlocutor
  );

  const { ref } = useInView({
    threshold: 1,
    onChange: inView => {
      if (inView && !isFetching) fetchPreviousPage();
    },
  });

  const containerRef = useRef<HTMLDivElement>(null);

  const chatReadMutation = useChatReadMutation(idUser, idInterlocutor);
  const messageReadMutation = useMessageReadMutation(idUser, idInterlocutor);

  let messages: JSX.Element[] | undefined;
  if (dialogData && interlocutorProfileData) {
    let previousDateString: string | undefined;
    const today = new Date().toDateString();

    const messagesData = data?.pages.flatMap(page => page.messages);

    const lastInterlocutorMessage = messagesData?.findLast(
      message => message.idUserFrom === idInterlocutor
    );
    if (
      lastInterlocutorMessage &&
      dialogData.idLastReadMsg !== lastInterlocutorMessage.id
    ) {
      if (!chatReadMutation.isPending) chatReadMutation.mutate();

      if (!messageReadMutation.isPending)
        messageReadMutation.mutate(lastInterlocutorMessage.id);
    }

    messages = messagesData?.flatMap((msgData, i, arr) => {
      const msgComponent = (
        <Message
          key={msgData.id}
          dialogData={dialogData}
          messageData={msgData}
          interlocutorProfileData={interlocutorProfileData}
          shouldDisplayAvatar={msgData.idUserFrom !== arr.at(i + 1)?.idUserFrom}
        />
      );

      const messageDate = new Date(msgData.dateCreated);
      if (previousDateString === messageDate.toDateString())
        return msgComponent;

      const dateComponent = (
        <div
          key={messageDate.toDateString()}
          className='text-center text-sm font-semibold text-white'
        >
          {messageDate.toDateString() === today
            ? 'TODAY'
            : messageDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              })}
        </div>
      );

      previousDateString = messageDate.toDateString();
      return [dateComponent, msgComponent];
    });
    messages?.unshift(<div key='dummy' ref={ref} className='mt-auto'></div>);
  }

  const prevLastMessageIdRef = useRef<number>();

  useEffect(() => {
    if (!containerRef.current) return;

    const lastMessageId = data?.pages.at(-1)?.messages.at(-1)?.id;

    if (lastMessageId !== prevLastMessageIdRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
      prevLastMessageIdRef.current = lastMessageId;

      dismissToast('message', idUser, idInterlocutor);
    }
  }, [messages]);

  return (
    <div
      ref={containerRef}
      className='relative flex grow flex-col gap-2 overflow-y-scroll bg-gradient-to-t from-blue-300 to-blue-200 p-2'
    >
      <div className='fixed z-10 self-center pt-2'>
        <RestrictionInfo
          idUser={idUser}
          idInterlocutor={idInterlocutor}
          isBlocked={dialogData?.isBlocked}
        />
      </div>
      {messages}
    </div>
  );
}
