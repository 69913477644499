import { useMutation } from '@tanstack/react-query';

export default function useSentPostMutation() {
  return useMutation({
    mutationFn: async (idUser: number) => {
      const params = new URLSearchParams({
        idUser: idUser.toString(),
      });

      const res = await fetch(
        '/api/v1/news-feed/send-last-post?' + params.toString(),
        {
          method: 'POST',
        }
      );

      if (res.ok) return res;

      const parsed = (await res.json()) as { error: string };

      if (
        res.status === 400 &&
        parsed.error === 'daily_sent_post_limit_exceeded'
      )
        throw new Error('⚠️ Limit');

      if (res.status === 404 && parsed.error === "can't find post id")
        throw new Error('❌ No posts');

      throw new Error('‼️ Error');
    },
  });
}
