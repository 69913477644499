import { InfiniteData, QueryClient } from '@tanstack/react-query';
import dialogQueryOptions from './queries/dialog';
import { DialogData, MessageData } from './types/dialog';

export default function addNewMessage(
  client: QueryClient,
  idUser: number,
  idInterlocutor: number,
  newMessage: MessageData
) {
  client.setQueryData<InfiniteData<{ messages: MessageData[] }, unknown>>(
    ['messages', { idUser, idInterlocutor }],
    old => {
      if (!old) return;

      const newLastPage = {
        messages: old.pages.at(-1)?.messages.concat(newMessage) ?? [newMessage],
      };

      return {
        pageParams: old.pageParams,
        pages: old.pages.with(-1, newLastPage),
      } satisfies InfiniteData<{ messages: MessageData[] }, unknown>;
    }
  );

  client.setQueryData<DialogData>(
    dialogQueryOptions(client, idUser, idInterlocutor).queryKey,
    old => {
      if (!old) return;

      return {
        ...old,
        dateUpdated: newMessage.dateCreated,
        lastMessage: newMessage,
      };
    }
  );

  client.setQueriesData<
    InfiniteData<
      {
        cursor: string;
        dialogs: {
          idUser: number;
          idInterlocutor: number;
        }[];
      },
      unknown
    >
  >({ queryKey: ['chats'] }, old => {
    if (!old) return;

    const topDialog = [{ idUser, idInterlocutor }];

    const newPages = old.pages.map((page, i) => {
      const filteredPage = page.dialogs.filter(
        dialog =>
          !(
            dialog.idUser === idUser && dialog.idInterlocutor === idInterlocutor
          )
      );

      return {
        cursor: page.cursor,
        dialogs: i === 0 ? topDialog.concat(filteredPage) : filteredPage,
      };
    });

    return {
      pageParams: old.pageParams,
      pages: newPages,
    };
  });
}
