import { useQuery } from '@tanstack/react-query';

type Sticker = {
  id: number;
  url: string;
};

type AllStickers = {
  categories: Array<{
    name: string;
    stickers: Sticker[];
  }>;
};

export function useAllStickersQuery(idUser: string) {
  return useQuery({
    queryKey: ['stickers', 'all', idUser],
    queryFn: async () => {
      const res = await fetch('/api/v1/chat/stickers?idUser=' + idUser);

      return res.json() as Promise<AllStickers>;
    },
  });
}

const recentLimit = 10;

export function useRecentStickersQuery(idUser: string) {
  return useQuery({
    queryKey: ['stickers', 'recent', idUser],
    queryFn: async () => {
      const params = new URLSearchParams({
        idUser: idUser,
        limit: recentLimit.toString(),
      });

      const res = await fetch(
        '/api/v1/chat/stickers/recent?' + params.toString()
      );

      return res.json() as Promise<Sticker[]>;
    },
    gcTime: 0,
  });
}
