import { cn } from '@/lib/utils';

export default function DoubleCheckIcon({
  className,
}: {
  className?: string;
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={cn('h-7 w-8', className)}
      viewBox='0 0 25 25'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.6 16.2 2.4 12 1 13.4 6.6 19l12-12-1.4-1.4L6.6 16.2zM11.2 16.2l-1.4 1.4 1.4 1.4 12-12-1.4-1.4-10.6 10.6z'
      ></path>
    </svg>
  );
}
