import { useEffect, useState } from 'react';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  type CarouselApi,
} from './ui/carousel';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';

export default function PhotoCarousel({
  images,
  index,
  setIndex,
}: {
  images: Array<{ id: number; url: string }>;
  index: number | null;
  setIndex: (value: number | null) => void;
}) {
  const [mainApi, setMainApi] = useState<CarouselApi>();
  const [asideApi, setAsideApi] = useState<CarouselApi>();

  useEffect(() => {
    if (!mainApi || !asideApi || index === null) return;

    mainApi.scrollTo(index, true);
    mainApi.on('select', api => setIndex(api.selectedScrollSnap()));

    asideApi.scrollTo(index, true);
  }, [mainApi, asideApi, index]);

  if (index === null) return;

  return (
    <Dialog open onOpenChange={() => setIndex(null)}>
      <DialogContent className='h-[83vh] w-[63vw] max-w-screen-xl'>
        <DialogHeader>
          <DialogTitle className='text-center'>
            Photos {index + 1}/{images.length}
          </DialogTitle>
        </DialogHeader>
        <div className='flex h-[70vh] gap-4'>
          <div className='py-12'>
            <Carousel
              setApi={setAsideApi}
              opts={{ align: 'center' }}
              orientation='vertical'
              className='w-16'
            >
              <CarouselContent className='h-[60vh]'>
                {images.map((image, i) => (
                  <CarouselItem key={image.id} className='basis-1/6'>
                    <img
                      src={image.url}
                      alt=''
                      className='h-16 w-16 cursor-pointer object-cover'
                      onClick={() => setIndex(i)}
                    />
                  </CarouselItem>
                ))}
              </CarouselContent>
              <CarouselPrevious />
              <CarouselNext />
            </Carousel>
          </div>
          <div className='w-[55vw] px-14'>
            <Carousel setApi={setMainApi} className='h-full'>
              <CarouselContent className=''>
                {images.map(image => (
                  <CarouselItem key={image.id}>
                    <div
                      className='relative h-[70vh] w-full bg-cover bg-center '
                      style={{ backgroundImage: `url(${image.url})` }}
                    >
                      <div className='absolute top-0 mx-auto flex h-full w-full justify-center bg-contain bg-no-repeat backdrop-blur-sm'>
                        <div
                          className='h-full w-full bg-contain bg-center bg-no-repeat'
                          style={{ backgroundImage: `url(${image.url})` }}
                        />
                      </div>
                    </div>
                  </CarouselItem>
                ))}
              </CarouselContent>
              <CarouselPrevious />
              <CarouselNext />
            </Carousel>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
