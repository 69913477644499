import useBookmarkMutation from '@/lib/mutations/bookmark';
import byPairsQueryOptions from '@/lib/queries/byPairs';
import dialogQueryOptions from '@/lib/queries/dialog';
import { BookmarkFilledIcon, BookmarkIcon } from '@radix-ui/react-icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export default function BookmarkButton({
  idUser,
  idInterlocutor,
}: {
  idUser: number;
  idInterlocutor: number;
}) {
  const qc = useQueryClient();

  const dialogData = useQuery(
    dialogQueryOptions(qc, idUser, idInterlocutor)
  ).data;

  useQuery({
    ...byPairsQueryOptions(qc, idInterlocutor, idUser),
    enabled: !dialogData,
  });

  const { mutate, isPending } = useBookmarkMutation(idUser, idInterlocutor);

  return (
    dialogData && (
      <button
        onClick={() => mutate(!dialogData.isBookmarked)}
        disabled={isPending}
      >
        {dialogData.isBookmarked ? (
          <BookmarkFilledIcon className='h-6 w-6 text-red-600' />
        ) : (
          <BookmarkIcon className='h-6 w-6' />
        )}
      </button>
    )
  );
}
